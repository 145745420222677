import React, { Component } from "react";

export class Empresas extends Component {
    render() {

        return (
            <div id="Bussines">
                <div className="container">

                    <div className="capital">
                        {this.props.data ? this.props.data.capital_title.map(capital =>

                            <div className="section-title">
                                <h2>{capital.title}</h2>
                                <p>{capital.text}</p>
                            </div>
                        ) : "loading"}

                        <div className="capital_box">
                            {this.props.data ? this.props.data.capital.map(capital =>
                                <div className="empresas-box" style={{ background: capital.id ? "#f4f4f4" : "white" }}>
                                    <img src={capital.icon} className="img-empresa" alt="" />
                                    <p> <strong>{capital.text}</strong> </p>
                                </div>
                            ) : "loading"}
                        </div>
                    </div>
                </div>

                <div className="empresas_body">
                    <div className="container">
                        {this.props.data ? this.props.data.empresa_title.map(empresa =>

                            <div className="section-title">
                                <h2>{empresa.title}</h2>
                                <p>{empresa.text}</p>
                            </div>
                        ) : "loading"}

                        <div className="empresas_version">
                            {this.props.data ? this.props.data.version_empresa.map((empresa) =>
                                <div className="empresas-box_version">
                                    <img src={empresa.icon} className="img-empresa" alt="" />
                                    <p className="empresa-size"><strong>{empresa.text}</strong></p>
                                </div>
                            ) : "loading"}
                        </div>

                        <div className="empresas">
                            {this.props.data ? this.props.data.empresa.map((empresa) =>
                                <div className="empresas-box">
                                    <img src={empresa.icon} className="img-empresa" alt="" />
                                    <p>{empresa.colaboradores}</p>
                                    <p className="empresa-size"><strong>{empresa.text}</strong></p>
                                </div>
                            ) : "loading"}
                        </div>
                    </div>
                </div>

                <div className="Gestion_humana">
                    <div className="container">
                        {this.props.data ? this.props.data.Gestion_humana_title.map(gestion =>
                            <div className="section-title">
                                <h2>{gestion.title}</h2>
                                <p>{gestion.text}</p>
                            </div>
                        ) : "loading"}

                        <div className="gestion_body">
                            {this.props.data ? this.props.data.Gestion_humana.map(gestion =>
                                <div className="gestion_box">
                                    <img src={gestion.icon} className="img-empresa" alt="" />
                                    <p>{gestion.text}</p>
                                </div>
                            ) : "loading"}
                        </div>
                    </div>
                </div>

                <div className="Seguridad_nube">
                    <div className="container">
                        {this.props.data ? this.props.data.Seguridad_nube_title.map(seguridad =>
                            <div className="section-title">
                                <h2>{seguridad.title}</h2>
                            </div>
                        ) : "loading"}
                    </div>
                </div>



            </div>
        )
    }
}



export default Empresas;