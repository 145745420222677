import React, { Component } from "react";
import Footer from './footer';
import Navigation from './navigation';
import JsonData from '../data/data.json';



export default class Politicas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            landingPageData: {},
        }



    }

    //funcion que carga el json con las paginas a mostrar en un estado

    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }

    //funcion que se carga al momento de cargar la pagina y llama a la funcion getlandingPageData

    componentDidMount() {
        this.getlandingPageData();
    }

    render() {
        return (
            <div>
                <Navigation />

                <div id="politicas">
                    <div className="container">


                        <div className="section-title">
                            <h2>{this.props.data.title}</h2>
                        </div>

                        <div className="col-md-12">
                          
                                {this.props.data ? this.props.data.texts && this.props.data.texts.map((item) =>
                                    <p>{item.text}</p>
                                ) : "loading"}
                          
                        </div>


                    </div>
                </div>
                <Footer data={this.state.landingPageData.Ciudades} />
            </div>
        )
    }
}

