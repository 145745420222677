import React, { Component } from "react";

export class Nosotros extends Component {
  render() {
    // componente que se renderiza en "/#nosotros"
    return (
      <div className="text-center">



        <div id="somos-gestion">
          <div className="container">
            <div className="section-title">
              <h2>Somos gestión</h2>

              <div className="section-desc">
                <p>
                  Creemos en la tecnología como herramienta de transformación de los entornos laborales. Generamos espacios y oportunidades que brinden mejores condiciones a los colaboradores.
                </p>
              </div>

            </div>
            <div className="row">
              {this.props.data
                ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <img src={d.icon} alt="" />
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
                : "loading"}
            </div>

          </div>


        </div>

        <div id="nosotros">

          <div className="container">



            <div className="section-title">
              <h2>Nuestra historia</h2>

            </div>

            <div className="section-desc">
              <p>
                Desde la realización de los primeros sistemas para el procesamiento de pagos de nómina y procesos contables… hace más de 40 años, Heinsohn ha continuado ampliando su portafolio de servicios, siempre a la vanguardia de la tecnología y cercana a los nuevos retos que enfrentan nuestros clientes.
              </p>



              <div className="section-title">
                <h2>Quiénes somos</h2>
              </div>
              <p>
                Somos una comunidad de analistas, ingenieros, emprendedores, estudiantes, profesores, y más. Nuestros valores compartidos y creencias influencian el trabajo que hacemos, y el impacto que esperamos lograr.
              </p>

              <div className="section-title">
                <h2>Cómo trabajamos</h2>
              </div>
              <p>
                Como especialistas en procesos de pago, administración de personal y gestión del talento humano, nos mantenemos centrados en lograr la eficiencia operacional en su organización, midiendo continuamente los impactos positivos que logramos con cada servicio prestado. Buscamos continuamente trabajar de la mano de nuestros clientes, entendiendo sus necesidades y retos, para finalmente idear la mejor oferta de solución.
              </p>

              <p>
                Somos apasionados por lo que hacemos, por eso <strong>tratamos de mantener un espíritu de aprendizaje, nos relacionamos con actores que aportan valor a lo que hacemos</strong> y nos ayudan a conocer más nuestro negocio.
              </p>

              <div className="section-title">
                <h2>Misión</h2>
              </div>
              <p>
                Ser su aliado en:
              </p>
              <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                <ul style={{ fontSize: "20px" }}>
                  <li>Transformar las estrategias organizacionales en realidad. </li>
                  <li>Comprometer a los colaboradores e impulsar su desempeño. </li>
                  <li>Generar experiencias de bienestar a los colaboradores. </li>
                  <li>Crear valor y marcar la diferencia. </li>
                </ul>
              </div>


              <div className="section-title">
                <h2>Visión</h2>
              </div>
              <p>
                Ser reconocidos como la mejor alternativa en soluciones de Gestión de Nómina y Relaciones Laborales, Consultoría en Tecnología de Recursos Humanos y Gestión Humana y nuestra capacidad de innovación.
              </p>
              <div className="section-title">
                <h2>Certificados</h2>
              </div>

              <div id="row" className="col-md-12 row-aliados">
                <div className="col-md-3">
                  <div className="thumbnail">
                    {" "}
                    <img src={"/img/certificados/ISO-IEC270012013.webp"} alt="Certificacion ISO-IEC270012013" className="img-certificados" />
                    <div >
                      <h4>Certificado</h4>
                      <p>ISO/IEC 27001:2013</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="thumbnail">
                    {" "}
                    <img src={"/img/certificados/LogoISO9001.webp"} alt="Certificacion ISO 90012" className="img-certificados" />
                    <div >
                      <h4>Certificado</h4>
                      <p>ISO-9001</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Nosotros;
