import React, { Component } from "react";
import Navigation from './navigation';
import Footer from './footer'
import JsonData from '../data/data.json';


import {
    Button,
    Accordion,
    Card,
} from 'react-bootstrap';

export class politicasHeinsohnHGS extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activateEventKey: '',
            landingPageData: {},
        }


        
    }

    getlandingPageData() {
        this.setState({landingPageData : JsonData})
      }
    
      //funcion que se inicia cuando la pagina esta renderizada, llama a la funcion getlandingPageData
      componentDidMount() {
        this.getlandingPageData();
      }


      // funcion que recibe el id del acordeon dependiendo del id abre un acordeon nuevo o si es el mismo id cierra el actual
    Accordion(id) {
        if (this.state.activateEventKey === id) {
            this.setState({
                activateEventKey: ""
            })
        } else {

            this.setState({
                activateEventKey: id
            })
        }
    }

    render() {
        return(
            <div id='condiciones'>
                <div className="container">
                    <Navigation />
                    <div className="columna">
                        <div className="row">
                            <div>
                                <div className="section-title">
                                    <h2>Política de Calidad</h2>
                                </div>

                                <p className="textos">
                                    HEINSOHN HGS está comprometido con satisfacer las necesidades de nuestros clientes y colaboradores, a través de la construcción de soluciones en Outsourcing de nómina y Gestión humana, consultoría y aplicación de las mejores tecnologías, que cumplan con los requerimientos y compromisos pactados, manteniendo como enfoque principal la búsqueda constante de oportunidades de mejora y los estándares de calidad requeridos para la definición y cumplimiento de nuestros objetivos, en los requerimientos legales aplicables y en la responsabilidad social frente a nuestras partes interesadas
                                </p>
                                <br />
                                <br />
                                <div className="section-title">
                                    <h2>Política general de seguridad de la información</h2>
                                </div>

                                <p className="textos">
                                    Para Heinsohn HGS la información en uno de los activos más importantes para la prestación de nuestros servicios en Gestión de Nómina y Relaciones Laborales, Consultoría en Gestión Humana y Consultoría en Tecnología de Recursos Humanos a través de aplicativos especializados en la sede de la Carrera 13 # 82-49 en la ciudad de Bogotá ; por esta razón es prioridad que todos nuestros clientes, colaboradores, proveedores, contratistas, socios y personas de interés en general, conozcan y acepten el compromiso como organización en la protección de la información, cumpliendo los principios de Confidencialidad, Integridad y Disponibilidad...
                                </p>
                            </div>
                            <br />
                            <br />
                            <div>
                                <div className="section-title">
                                    <h2>Política de Cookies</h2>
                                </div>

                                <Accordion>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="12" onClick={() => this.Accordion(12)} className="accordion-color">
                                                {this.state.activateEventKey !== 12 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 12 && <span className="accordion-collapse">- </span>}
                                                General
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="12">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Heinsohn Human Global Solutions S.A.S. respetan su privacidad. Esta política describe el tipo de información que podríamos recopilar en nuestra página Web y su uso. Esta política puede ser actualizada ocasionalmente, de modo que recomendamos consultarla de vez en cuando. Al utilizar esta página y/o al proporcionar información personal, usted expresa su conformidad con los términos de esta política.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="13" onClick={() => this.Accordion(13)} className="accordion-color">
                                                {this.state.activateEventKey !== 13 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 13 && <span className="accordion-collapse">- </span>}
                                                Recopilación y utilización de información personal
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="13">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    La información personal incluye información como su nombre, dirección, número telefónico, empresa donde labora y dirección electrónica. Generalmente, usted puede visitar nuestra página Web sin proporcionar ninguna información personal. Sin embargo, necesitamos recopilar su información personal para propósitos comerciales cuando solicite ampliar la información sobre un producto o contacto con un Experto Heinsohn. En caso de ser necesario utilizar su información personal para otros fines, se lo comunicaremos al momento de obtenerla. Al proporcionarnos esta información, usted estará dando su consentimiento para utilizar y revelar su información personal, para los fines notificados.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="14" onClick={() => this.Accordion(14)} className="accordion-color">
                                                {this.state.activateEventKey !== 14 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 14 && <span className="accordion-collapse">- </span>}
                                                Revelación
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="14">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Una cookie es un texto que solicita permiso para ser colocado en su disco duro. Si usted accede (o si su navegador lo hace automáticamente, cuando está configurado de ese modo), su navegador agrega ese texto en un archivo pequeño. Una cookie ayuda a analizar el tráfico por Internet o deja que los operadores de la página Web sepan cuando usted visita un sitio en particular. Las cookies permiten a las aplicaciones Web a responderle a usted individualmente. La aplicación de la Web puede personalizar sus operaciones de acuerdo con sus necesidades, preferencias u objeciones, al reunir y registrar la información sobre sus preferencias.  Hemos incluido una lista completa de las cookies usados en Talkingalcohol.com, la cual aparece a continuación, junto con una definición de para qué se utiliza la cookie. Heinsohn HGS no utilizará estas cookies para recoger información que pueda identificarlo a usted personalmente.
                                                </p>

                                                <h4 className="textos-accordion">Cómo controlar y borrar las cookies</h4>

                                                <p className="textos-accordion">
                                                    Sabemos que hay personas a quienes les preocupan las cookies, pero pensamos que, mediante el uso apropiado de los mismos, el beneficio obtenido bien vale la pena, tanto para usted como para Heinsohn HGS. Usted puede borrar los archivos que contienen cookies – esos archivos están guardados como parte de su navegador de Internet. Si desea restringir o bloquear las cookies que hayan sido configurados o si desea recibir notificación de las solicitudes para instalar cookies o si desea rechazar las cookies del todo, usted puede hacer esto mediante la configuración de su navegador. La función de Ayuda en su navegador le dará instrucciones para hacerlo. Por favor tenga en cuenta que al declinar o rechazar las cookies podría impedir la utilización del sitio Web en su plena capacidad. En forma alternativa, puede visitar
                                                <a href="http://www.bavaria.co/"> www.aboutcookies.org </a>
                                                que contiene amplia información sobre cómo hacerlo, en diversos navegadores de Internet. Ahí también encontrará detalles sobre cómo borrar las cookies de su computador, igual que información más general sobre las cookies. Para su información sobre cómo hacer esto en el navegador de su teléfono celular también, debe consultar el manual del usuario respectivo.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="15" onClick={() => this.Accordion(15)} className="accordion-color">
                                                {this.state.activateEventKey !== 15 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 15 && <span className="accordion-collapse">- </span>}
                                                Los cookies y demás información sin identificación
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="15">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    La cultura y la posición de nuestra compañía se fundamenta en cero tolerancia frente al Fraude. Por lo anterior, Heinsohn HGS busca permanentemente implementar las mejores prácticas contra estas actividades.
                                                </p>
                                                <p className="textos-accordion">
                                                    En aras de cumplir la política, Heinsohn:
                                                </p>
                                                <ul className="lista">
                                                    <li>
                                                        Se abstiene de participar en cualquier forma de fraude, soborno, o práctica de corrupción, directa o indirectamente.
                                                    </li>
                                                    <li>
                                                        Promueve y establece dentro de toda la organización, una cultura institucional antifraude, antisoborno y anticorrupción.
                                                    </li>
                                                    <li>
                                                        No tolera que sus Empleados, Administradores, Accionistas, Directores, Proveedores y Terceros Asociados, obtengan resultados económicos, comerciales o de cualquier otra índole a nivel directo o indirecto, a cambio de violar la ley o actuar de manera deshonesta.
                                                    </li>
                                                    <li>
                                                        Cuenta con el código de ética el cual regula la conducta de los empleados con el fin de prevenir la promoción de cualquier forma de Fraude. Los lineamientos y reglas del código de ética se entienden incorporados a la presente política.
                                                    </li>
                                                    <li>
                                                        Genera un entorno de transparencia, manteniendo los canales adecuados para favorecer la comunicación de dichos asuntos al interior de la organización y coordinando el conjunto de acciones necesarias para prevenir, detectar y dar respuesta a posibles situaciones de fraude y corrupción.
                                                    </li>
                                                    <li>
                                                        Toma las medidas necesarias a nivel disciplinario para combatir el fraude, el soborno y la corrupción, de cualquier forma, o tipología de la que se trate.
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="16" onClick={() => this.Accordion(16)} className="accordion-color">
                                                {this.state.activateEventKey !== 16 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 16 && <span className="accordion-collapse">- </span>}
                                                Seguridad y Precisión
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="16">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Consideramos importante la precisión y seguridad de sus datos personales y hemos establecido procedimientos físicos y electrónicos para poder conservar la seguridad de su información personal. Por lo tanto, solamente el personal administrativo autorizado tendrá acceso a esta información. En caso de habernos enviado información personal que desean borrar de nuestros registros o que quisieran actualizar, favor utilizar los detalles de contacto correspondientes en nuestra página “Contáctenos”. Concédanos el tiempo necesario para actualizar sus datos.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="17" onClick={() => this.Accordion(17)} className="accordion-color">
                                                {this.state.activateEventKey !== 17 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 17 && <span className="accordion-collapse">- </span>}
                                                Transferencias
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="17">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Cualquier información personal que recopilemos será guardada en el país donde se recoge y podrá ser transferida también de otros países a Colombia.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="18" onClick={() => this.Accordion(18)} className="accordion-color">
                                                {this.state.activateEventKey !== 18 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 18 && <span className="accordion-collapse">- </span>}
                                                Contáctenos
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="18">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Para preguntas adicionales, favor visite la página: Contáctenos a <a href="mailto:servicioalcliente@heinsohn.com.co">servicioalcliente@heinsohn.com.co</a> para conocer detalles acerca de cómo enviar preguntas y sugerencias, respecto a esta política de privacidad.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                </Accordion>
                            </div>
                            <br />
                            <br />
                            <div>
                                <div className="section-title">
                                    <h2>Política de Gestión del Fraude</h2>
                                </div>

                                <Accordion>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="4" onClick={() => this.Accordion(4)} className="accordion-color">
                                                {this.state.activateEventKey !== 4 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 4 && <span className="accordion-collapse">- </span>}
                                                Objetivos
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    El objetivo principal de esta política es establecer los lineamientos y parámetros generales que deben ser seguidos por todos los Empleados, Administradores, Accionistas, Directores y partes interesadas al grupo empresarial Heinsohn Business Technolohy SA (en adelante Heinsohn), con el fin de minimizar la materialización de situaciones asociadas al Fraude.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="5" onClick={() => this.Accordion(5)} className="accordion-color">
                                                {this.state.activateEventKey !== 5 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 5 && <span className="accordion-collapse">- </span>}
                                                Alcance
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Aplica a todos los colaboradores que ingresen a la compañía a partir de la publicación y divulgación de la presente política, empleados, administradores, Accionistas, Directores y partes interesadas al grupo empresarial Heinsohn.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="6" onClick={() => this.Accordion(6)} className="accordion-color">
                                                {this.state.activateEventKey !== 6 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 6 && <span className="accordion-collapse">- </span>}
                                                Duración
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Permanente.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="7" onClick={() => this.Accordion(7)} className="accordion-color">
                                                {this.state.activateEventKey !== 7 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 7 && <span className="accordion-collapse">- </span>}
                                                Descripción
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="7">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    La cultura y la posición de nuestra compañía se fundamenta en cero tolerancia frente al Fraude. Por lo anterior, Heinsohn HGS busca permanentemente implementar las mejores prácticas contra estas actividades.
                                                </p>
                                                <p className="textos-accordion">
                                                    En aras de cumplir la política, Heinsohn:
                                                </p>
                                                <ul className="lista">
                                                    <li>
                                                        Se abstiene de participar en cualquier forma de fraude, soborno, o práctica de corrupción, directa o indirectamente.
                                                    </li>
                                                    <li>
                                                        Promueve y establece dentro de toda la organización, una cultura institucional antifraude, antisoborno y anticorrupción.
                                                    </li>
                                                    <li>
                                                        No tolera que sus Empleados, Administradores, Accionistas, Directores, Proveedores y Terceros Asociados, obtengan resultados económicos, comerciales o de cualquier otra índole a nivel directo o indirecto, a cambio de violar la ley o actuar de manera deshonesta.
                                                    </li>
                                                    <li>
                                                        Cuenta con el código de ética el cual regula la conducta de los empleados con el fin de prevenir la promoción de cualquier forma de Fraude. Los lineamientos y reglas del código de ética se entienden incorporados a la presente política.
                                                    </li>
                                                    <li>
                                                        Genera un entorno de transparencia, manteniendo los canales adecuados para favorecer la comunicación de dichos asuntos al interior de la organización y coordinando el conjunto de acciones necesarias para prevenir, detectar y dar respuesta a posibles situaciones de fraude y corrupción.
                                                    </li>
                                                    <li>
                                                        Toma las medidas necesarias a nivel disciplinario para combatir el fraude, el soborno y la corrupción, de cualquier forma, o tipología de la que se trate.
                                                    </li>
                                                </ul>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="8" onClick={() => this.Accordion(8)} className="accordion-color">
                                                {this.state.activateEventKey !== 8 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 8 && <span className="accordion-collapse">- </span>}
                                                Uso Interno
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="8">
                                            <Card.Body className="accordion-body">
                                                <ul className="lista">
                                                    <li>
                                                        Prioriza las actividades de prevención de fraude, soborno y corrupción, sin disminuir los esfuerzos encaminados a la detección y corrección de situaciones relacionados con los mismos flagelos.
                                                    </li>
                                                    <li>
                                                        Evalúa los indicios de presuntos actos de fraude, soborno o corrupción, bajo los principios de confidencialidad, integridad, transparencia, objetividad, independencia y autonomía de los responsables de las evaluaciones.
                                                    </li>
                                                    <li>
                                                        Gestiona de forma oportuna todas las denuncias de actos relacionados con fraude, soborno o corrupción, independientemente de su cuantía o del personal involucrado, garantizando confidencialidad, objetividad, respeto y transparencia. Ningún funcionario sufrirá consecuencias negativas por prevenir, rechazar o denunciar un acto de esta naturaleza.
                                                    </li>
                                                    <li>
                                                        No mantiene vínculos con Empleados, Administradores, Directores, Proveedores o Terceros Asociados que hayan sido condenadas por actividades delictivas relacionadas con fraude, soborno o corrupción.
                                                    </li>
                                                    <li>
                                                        Cuenta con el canal de comunicación de transparencia, a través del cual se reciben las denuncias del incumplimiento de lo dispuesto en código de ética, incluyendo los actos incorrectos relacionados con fraude, soborno y corrupción.
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="9" onClick={() => this.Accordion(9)} className="accordion-color">
                                                {this.state.activateEventKey !== 9 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 9 && <span className="accordion-collapse">- </span>}
                                                Procesos para Evitar Fraude
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="9">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Heinsohn contempla dentro de sus procesos y áreas de negocio, las siguientes actividades para evitar el fraude:
                                                </p>

                                                <ul className="lista">
                                                    <li>
                                                        Selección y reclutamiento de personal: Con el fin de contratar personal idóneo y de confianza que cumpla con el código de ética, dentro del proceso de selección y reclutamiento de personal, Heinsohn cuenta con un proceso de verificación (Estudio de Seguridad) antes de realizar la contratación de personal.
                                                    </li>
                                                    <li>
                                                        Selección y conocimiento del cliente: Heinsohn realiza una validación previa a los prospectos antes de establecer un vínculo comercial. Para lo cual, el área de mercadeo realiza una validación SARLAFT.  En caso de presentar un resultado que incumpla o no se alinee con la Política de Gestión del Fraude de la compañía, se prohíbe iniciar relaciones comerciales con dicho tercero.
                                                    </li>
                                                    <li>
                                                        Firma de compromisos y políticas: Al momento de iniciar cualquier relación laboral con terceros, Heinsohn suscribirá un acta de aceptación de nuestras Políticas de Gestión del Fraude.
                                                    </li>
                                                    <li>
                                                        Actividades de control: El área financiera de Heinsohn realizará trimestralmente auditoria a las cuentas bancarias para poder identificar cualquier indicio de fraude. Por otra parte, se realizará por parte de la revisoría fiscal, tres auditorias al año, donde se realiza una revisión minuciosa de la información financiera de la compañía.
                                                    </li>
                                                    <li>
                                                        Control de acceso de usuarios de TI: Existen procedimientos formales para controlar el correcto acceso a los sistemas de tecnología. Los procedimientos de acceso deberán abarcar todas las etapas del ciclo de vida de los usuarios, desde el registro inicial de nuevos usuarios, hasta el retiro de estos.
                                                    </li>
                                                    <li>
                                                        Pagos de facilitación: se prohíben los pagos de facilitaciones, entendidas como los pagos efectuados para asegurar o agilizar el cumplimiento de las obligaciones de Heinsohn con terceros.
                                                    </li>
                                                    <li>
                                                        Regalos y entretenimiento: Los empleados deberán abstenerse de recibir u ofrecer regalos o entretenimiento que superen el 10% SMMLV (Salario Mínimo Mensual Legal Vigente) y que estén en contravía de la presente política. Se podrán recibir u ofrecer obsequios empresariales[1] que no superen el monto establecido anteriormente.
                                                    </li>
                                                </ul>
                                                <p className="textos-accordion">
                                                    [1] Se entiende como obsequios empresariales cualquier elemento dado o recibido como consecuencia de una relación comercial y por el que no se paga un valor en el mercado. Ejemplo agendas, esferos, mugs, etc.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="10" onClick={() => this.Accordion(10)} className="accordion-color">
                                                {this.state.activateEventKey !== 10 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 10 && <span className="accordion-collapse">- </span>}
                                                Procesos para Reportar Fraude
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="10">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Heinsohn dispondrá del siguiente correo electrónico para que los empleados o cualquier tercero pueda reportar un posible <strong> fraude, robo o cualquier irregularidad:</strong>
                                                </p>
                                                <p className="textos-accordion">
                                                    <a href="mailto:reportefraude@heinsohn.com.co">reportefraude@heinsohn.com.co</a>
                                                </p>

                                                <p className="textos-accordion">
                                                    Dentro del correo electrónico se deberá incluir los terceros relacionados con el fraude y los hechos que sustentan el mismo. Heinsohn mediante esta política garantizará la confidencialidad del reporte realizado.
                                                </p>

                                                <p className="textos-accordion">
                                                    Para empleados de Heinsohn también se dispone de la mesa de servicio interna en la cual se podrá registrar incidente, fraude, robo o cualquier irregularidad, dentro de la opción ““Reporte Fraude
                                                </p>

                                                <p className="textos-accordion">
                                                    Con el fin de mantener la confidencialidad y evitar poner en peligro las investigaciones, los empleados no deberán hacer comentario alguno al respecto con otros empleados o con cualquier otra persona, para evitar fugas de información.
                                                </p>

                                                <p className="textos-accordion">
                                                    Heinsohn procederá a realizar las respectivas investigaciones del fraude, robo o cualquier irregularidad con las áreas relacionadas con el mismo. Si dentro de las investigaciones se identifica que efectivamente se materializa dicho fraude, robo o irregularidad, se deberá proceder obligatoriamente a evaluar las sanciones.
                                                </p>

                                                <p className="textos-accordion">
                                                    Después de que la investigación se ha completado, es necesario determinar qué acción se tomará en razón de los hallazgos. En algunos casos puede ser necesario tomar ciertas acciones antes de que la investigación esté terminada (por ejemplo, preservar las pruebas, mantener la confianza en los testigos o mitigar las pérdidas). Esto podría requerir la suspensión o cambio físico de los individuos o acciones legales para asegurar los activos.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="11" onClick={() => this.Accordion(11)} className="accordion-color">
                                                {this.state.activateEventKey !== 11 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 11 && <span className="accordion-collapse">- </span>}
                                                Sanciones
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="11">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Después de realizar la respectiva investigación, cuando aplique, resultará la imposición de las sanciones disciplinarias que apliquen de acuerdo con el código sustantivo del trabajo y legales. Cualquier acción que se aplique a todos los niveles de los empleados, incluidos los altos directivos, debe ser adecuada a las circunstancias, y debe tomarse sólo después de consultar con los responsables de tales decisiones. La consulta con el área legal y de gestión humana es necesaria antes de tomar una acción disciplinaria, civil o penal.
                                                </p>
                                                <p className="textos-accordion">
                                                    Toda investigación y sanción disciplinaria se deberá llevar a cabo sin hacer distinción de la posición del infractor, el cargo o la relación que guarde con nuestra compañía.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                </Accordion>


                            </div>
                        </div>
                    </div>
                </div>
                <br/>

                <Footer data={this.state.landingPageData.Ciudades}/>
            </div>
        )
    }
}

export default politicasHeinsohnHGS;