import React, { Component } from "react";

export class aliados extends Component {
  render() {

  // renderiza la seccion aliados en la ruta "/"

    return (
      <div id="aliados" className="text-center">
        <div className="container">
          <div className="col-md-12 section-title">
            <h2>Somos equipo</h2>
            <p>
            El trabajo en equipo es una de nuestras principales estrategias. Estamos convencidos que el poder colectivo es la herramienta esencial para generar soluciones innovadoras. Como grupo empresarial unimos nuestra fuerza a grandes empresas que hoy son nuestros aliados tecnológicos, creativos y financieros.
            </p>
          </div>
          <div id="row" className="row-aliados col-md-12">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-3 team">
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default aliados;
