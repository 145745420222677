import React, { Component } from "react";
import Navigation from './navigation';
import { Route , withRouter} from 'react-router-dom';
import JsonData from '../data/data.json';
import Footer from './footer'



export class mensajeEnviado extends Component {

    constructor(props){
        super(props)
        this.state = {
            landingPageData: {},
        }
    }

    //funcion que carga el json en un estado
    getlandingPageData() {
        this.setState({landingPageData : JsonData})
      }
    

      //funcion que se carga al momento de cargar la ruta "/message" y llama a la funcion getlandingPageData
      componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-794607960";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "js/mensajeEnviado.js"
        document.head.appendChild(script2);

        const script3 = document.createElement("script");
        script3.src = "js/mensajeEnviadoPixel.js"
        document.head.appendChild(script3);


        this.getlandingPageData();
      }

    render(){
        
        return(
            <div id="mensaje-enviado">
                <div className="container">
                <Navigation />

                

                <div className="columna">
                        <div className="row">

                            

                        <div className="section-title">

                            {/*<img className="banner" src="img/mensaje-enviado/confirmacion_mensaje.png" alt=""/>*/}
                                
                                <h2>Agendaste una cita</h2>
                            </div>

                            <i className="icon-mail-sent-2 iconos"></i>
                            <h3>
                            Pronto un experto se comunicará contigo para brindarte información adicional y que puedas tener nuestras soluciones o servicios a tu disposición
                            </h3>

                            
                           

                            <br/>
                            <br/>



                        </div>
                    </div>
                    

                </div>
                <br />
                <Footer data={this.state.landingPageData.Ciudades}/>
            </div>
        )
    }
}

export default mensajeEnviado;