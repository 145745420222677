import React, { Component } from "react";
import Navigation from './navigation';
import JsonData from '../data/data.json';
import PaginationList from 'react-pagination-list';
import Footer from './footer';

let ebooks;
export class ContentBlogs extends Component {

    constructor(props) {
        super(props)


        this.state = {
            landingPageData: {},
            ebooks: []
        }

    }


    // funcion que carga los datos del json en un estado
    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }

    // funcion encargada de llamar a la funcion getlandingPageData al momento de que la pagina esta renderizada
    componentDidMount() {
        this.getlandingPageData();
    }

    render() {

        //componente que renderiza el contenido de los e-book en la ruta "/contenido/e-books"

        if(this.props !== undefined && this.props.data !== undefined){
            let listEbooks = this.props.data.ebooks;
            let list = listEbooks.sort(function(a,b){
                return new Date(b.fecha) - new Date(a.fecha);
            });
            ebooks = list; 
        }
        return (
            <div id="content-b">
                <Navigation />
                <div className="container">

                    <div className="col-md-12 section-title">
                        <h1>{this.props.data ? this.props.data.title : "loading"}</h1>
                    </div>
                   

                        <PaginationList
                            data={ebooks}
                            pageSize={4}
                            renderItem={(d) => (
                                <div className="col-md-6">
                                    <div className="image-box">
                                        <img className="img" src={d.img} alt={d.alt} />
                                    </div>

                                    <div className="ebook-box">

                                        <div >
                                            <a href={d.link} className="link"><h4>{d.title}</h4></a>
                                        </div>

                                        <div className="texts-box">
                                            {d.texts.map(t =>
                                                <p className="ebook-text">{t.text}</p>
                                            )}
                                        </div>

                                        <div className="link-box">
                                            <a href={d.link} className="link">{d.textLink}</a><i className="icon-arrow-58 icon-ebooks"></i>
                                        </div>
                                    </div>

                                </div>
                            )}
                        />


                    </div>

                <Footer data={this.state.landingPageData.Ciudades} />


            </div>
        )
    }

}

export default ContentBlogs;