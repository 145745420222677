import React, { Component } from 'react'

export class productos extends Component {
  openUrl(url) {
    /*const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }*/
  }
  render() {

    //componente encargado de renderizar los productos en la ruta "/"
    return (
      <div id="productos">
        <div className="container">


          <div className="section-title text-center" id="contact-title">
            <h2>Productos</h2>
          </div>

          <div className="body-p">
            {this.props.data ? this.props.data.map((d, i) =>

              <a className="col-xs-12 col-md-6" href={d.urlBton}>

                <img src={d.src} className="image" alt="" />
                <div className="text-box">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                  <div className="a-box">
                    <a href={d.urlBton}>{d.nameBton}</a>
                  </div>
                </div>
              </a>

            ) : 'loading'}
          </div>

        </div>
      </div>
    )
  }
}

export default productos
