import React, { Component } from "react";
import Navigation from './navigation';
import Footer from './footer'
import JsonData from '../data/data.json';


import {
    Button,
    Accordion,
    Card,
} from 'react-bootstrap';

export class condiciones extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activateEventKey: '',
            landingPageData: {},
        }


        
    }

    //funcion que llama los datos del json y los asigna a un estado, que luego se le envia al footer
    getlandingPageData() {
        this.setState({landingPageData : JsonData})
      }
    
      //funcion que se inicia cuando la pagina esta renderizada, llama a la funcion getlandingPageData
      componentDidMount() {
        this.getlandingPageData();
      }


      // funcion que recibe el id del acordeon dependiendo del id abre un acordeon nuevo o si es el mismo id cierra el actual
    Accordion(id) {
        if (this.state.activateEventKey === id) {
            this.setState({
                activateEventKey: ""
            })
        } else {

            this.setState({
                activateEventKey: id
            })
        }
    }

    render() {

        //renderiza la pagina de condiciones en la ruta "/conditions"

        return (
            <div id='condiciones'>
                <div className="container">
                    <Navigation />
                    <div className="columna">
                        <div className="row">
                            <div className="section-title">
                                <h2>AVISO DE PRIVACIDAD</h2>
                            </div>

                            <div>
                                <p className="textos">
                                    Heinsohn Human Global Solutions S.A.S.,considerada como responsable del tratamiento de Datos Personales,
                                    (en adelante, “LA COMPAÑÍA”), en cumplimiento de lo previsto por la Ley 1581 de 2012, sus decretos reglamentarios y demás normas concordantes,
                                    le informa que los datos personales que usted suministre en virtud de las actividades u operaciones celebradas con LA COMPAÑIA,
                                    serán tratados de acuerdo a su política de Protección de Datos Personales y mediante el uso y mantenimiento de medidas de seguridad técnicas,
                                    físicas y administrativas a fin de impedir que terceros no autorizados accedan a los mismos.
                                </p>

                                <Accordion>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => this.Accordion(0)} className="accordion-color">
                                                {this.state.activateEventKey !== 0 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 0 && <span className="accordion-collapse">- </span>}
                                                Responsable del Tratamiento
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">Heinsohn Human Global Solutions S.A.S. entidad con domicilio en la Carrera 13 N° 82-49 de la ciudad de Bogotá D.C., Colombia.
                                                Para mayor información podrá contactarnos en el Correo electrónico protecciondedatos@heinsohn.com.co, o al teléfono +57 (1) 6337070.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => this.Accordion(1)} className="accordion-color">
                                                {this.state.activateEventKey !== 1 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 1 && <span className="accordion-collapse">- </span>}
                                                Tratamiento y Finalidad
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">Los Datos Personales suministrados serán recolectados, utilizados, transmitidos, transferidos, almacenados y procesados para las siguientes finalidades:</p>
                                                <ul className="lista">
                                                    <li>
                                                        Para cumplir las obligaciones contraídas por LA COMPAÑIA con sus clientes al momento de adquirir nuestros productos;
                                                    </li>

                                                    <li>
                                                        Enviar información sobre cambios en las condiciones de los productos ofrecidos por LA COMPAÑIA;
                                                    </li>

                                                    <li>
                                                        Enviar información sobre ofertas relacionadas con los productos que ofrece LA COMPAÑIA y sus compañías vinculadas;
                                                    </li>

                                                    <li>
                                                        Para el fortalecimiento de las relaciones con sus consumidores y clientes, mediante el envío de información relevante, la toma de pedidos y evaluación de la calidad del servicio;
                                                    </li>

                                                    <li>
                                                        Permitir la participación de los titulares en actividades de mercadeo y promocionales (incluyendo la participación en concursos, rifas y sorteos) realizados por LA COMPAÑIA;
                                                    </li>

                                                    <li>
                                                        Realizar estudios de mercado sobre hábitos de compra y análisis estadísticos para usos internos;
                                                    </li>

                                                    <li>
                                                        Para la determinación de obligaciones pendientes, la consulta de información financiera e historia
                                                        crediticia y el reporte a centrales de información de obligaciones incumplidas, respecto de sus deudores;
                                                    </li>

                                                    <li>
                                                        Para mejorar, promocionar y desarrollar sus productos y los de sus compañías vinculadas a nivel mundial;
                                                    </li>

                                                    <li>
                                                        Controlar el acceso a las oficinas de LA COMPAÑIA y establecer medidas de seguridad, incluyendo el establecimiento de zonas videovigiladas;
                                                    </li>

                                                    <li>
                                                        Dar respuesta a consultas, peticiones, quejas y reclamos que sean realizadas por los titulares y transmitir los Datos Personales a organismos de control y demás autoridades que en virtud de la ley aplicable deban recibir los Datos Personales;
                                                    </li>

                                                    <li>
                                                        Para eventualmente contactar, vía correo electrónico, o por cualquier otro medio, a personas naturales con quienes tiene o ha tenido relación, tales como, sin que la enumeración signifique limitación, trabajadores y familiares de éstos, accionistas, consumidores, clientes, distribuidores, proveedores, acreedores y deudores, para las finalidades antes mencionadas.
                                                    </li>

                                                    <li>
                                                        Registrar sus datos personales en los sistemas de información de LA COMPAÑIA y en sus bases de datos comerciales y operativas.
                                                    </li>

                                                    <li>
                                                        Administrar y operar, directamente o por conducto de terceros, los procesos de selección y vinculación de personal, incluyendo la evaluación y calificación de los participantes y la verificación de referencias laborales y personales, y la realización de estudios de seguridad;
                                                    </li>

                                                    <li>
                                                        Desarrollar las actividades propias de la gestión de recursos humanos dentro de LA COMPAÑIA, tales como nómina, afiliaciones a entidades del sistema general de seguridad social, actividades de bienestar y salud ocupacional, ejercicio de la potestad sancionatoria del empleador, entre otras;
                                                    </li>

                                                    <li>
                                                        Realizar los pagos necesarios derivados de la ejecución del contrato de trabajo y/o su terminación, y las demás prestaciones sociales a que haya lugar de conformidad con la ley aplicable;
                                                    </li>

                                                    <li>
                                                        Contratar beneficios laborales con terceros, tales como seguros de vida, gastos médicos, entre otros;
                                                    </li>

                                                    <li>
                                                        Notificar a contactos autorizados en caso de emergencias durante el horario de trabajo o con ocasión del desarrollo del mismo;
                                                    </li>

                                                    <li>
                                                        Coordinar el desarrollo profesional de los empleados, el acceso de los empleados a los recursos informáticos del Empleador y asistir en su utilización;
                                                    </li>

                                                    <li>
                                                        Para consolidar un suministro oportuno y de calidad con sus Proveedores, a través de la invitación a participar en procesos de selección, la evaluación del cumplimiento de sus obligaciones y la invitación a eventos organizados o patrocinados por LA COMPAÑIA, entre otros;
                                                    </li>

                                                    <li>
                                                        Transferir la información recolectada a distintas áreas de LA COMPAÑIA y a sus compañías vinculadas en Colombia y en el exterior cuando ello sea necesario para el desarrollo de sus operaciones y gestión de nómina (recaudo de cartera y cobros administrativo, tesorería, contabilidad, entre otros);
                                                    </li>

                                                    <li>
                                                        Registrar a los contratistas y proveedores en los sistemas de LA COMPAÑIA y procesar sus pagos;
                                                    </li>

                                                    <li>
                                                        Para la atención de requerimientos judiciales o administrativos y el cumplimiento de mandatos judiciales o legales;
                                                    </li>

                                                    <li>
                                                        Para procesamiento de pagos y verificación de saldos;
                                                    </li>

                                                    <li>
                                                        Capacitar vendedores y agentes en aspectos básicos de gestión comercial de los productos ofrecidos por LA COMPAÑIA;
                                                    </li>

                                                    <li>
                                                        Cualquier otra actividad de naturaleza similar a las anteriormente descritas que sean necesarias para desarrollar el objeto social de LA COMPAÑIA.
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={() => this.Accordion(2)} className="accordion-color">
                                                {this.state.activateEventKey !== 2 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 2 && <span className="accordion-collapse">- </span>}
                                                Derechos del Titular de Datos Personales
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">Las personas naturales cuyos Datos Personales sean objeto de Tratamiento por parte de la Compañía, tienen los derechos previstos en la Constitución Política de Colombia, la Ley 1581 de 2012 y sus decretos reglamentarios, y en particular:</p>
                                                <ul className="lista">
                                                    <li>
                                                        Conocer los Datos Personales sobre los cuales LA COMPAÑIA está realizando el Tratamiento. De igual manera, el titular puede solicitar en cualquier momento, que sus datos sean actualizados o rectificados, por ejemplo, si encuentra que sus datos son parciales, inexactos, incompletos, fraccionados, induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.
                                                    </li>
                                                    <li>
                                                        Solicitar prueba de la autorización otorgada a LA COMPAÑIA para el Tratamiento de sus Datos Personales.
                                                    </li>
                                                    <li>
                                                        Ser informado por LA COMPAÑIA, previa solicitud, respecto del uso que ésta le ha dado a sus Datos Personales.
                                                    </li>
                                                    <li>
                                                        Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley de Protección de Datos Personales.
                                                    </li>
                                                    <li>
                                                        Solicitar a LA COMPAÑIA la supresión de sus Datos Personales y/o revocar la autorización otorgada para el Tratamiento de los mismos, mediante la presentación de un reclamo, de acuerdo con los procedimientos establecidos en el numeral 11 de la Política de Protección de Datos Personales de LA COMPAÑIA. No obstante, la solicitud de supresión de la información y la revocatoria de la autorización no procederán cuando el Titular de la información tenga un deber legal o contractual de permanecer en la Base de Datos y/o archivos, ni mientras se encuentre vigente la relación entre el titular y LA COMPAÑIA, en virtud de la cual fueron recolectados sus datos.
                                                    </li>
                                                    <li>
                                                        Acceder de forma gratuita a sus Datos Personales objeto de Tratamiento.
                                                    </li>
                                                    <li>
                                                        Abstenerse de responder preguntas sobre datos sensibles. Tendrán carácter facultativo las respuestas que versen sobre niños, niñas y adolescentes y aquellas relacionadas con datos de salud.
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header className="accordion-title">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="3" onClick={() => this.Accordion(3)} className="accordion-color">
                                                {this.state.activateEventKey !== 3 && <span className="accordion-collapse">+ </span>}
                                                {this.state.activateEventKey === 3 && <span className="accordion-collapse">- </span>}
                                                Mecanismos para conocer la Política de Protección de Datos Personales
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body className="accordion-body">
                                                <p className="textos-accordion">
                                                    Los invitamos a conocer la Política de Protección de Datos Personales de LA COMPAÑÍA, la cual incluye los procedimientos para que los titulares de Datos Personales
                                                    puedan ejercer sus derechos a conocer, actualizar, rectificar y suprimir la información y revocar la autorización, y se encuentra disponible para su consulta en el
                                                    sitio web
                                                    <a href="https://www.heinsohn.com.co/"> www.heinsohn.com.co</a>.
                                                </p>

                                                <p className="textos-accordion">
                                                    Para dudas e inquietudes relacionadas con estos temas puede escribirnos al correo electrónico
                                                    <a href="mailto:protecciondedatos@heinsohn.com.co"> protecciondedatos@heinsohn.com.co</a>.
                                                </p>
                                                <p className="textos-accordion">
                                                    Bogotá D.C., 23 de Febrero de 2018.
                                                </p>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                </div>
                <br/>

                <Footer data={this.state.landingPageData.Ciudades}/>



            </div>

        )
    }
}

export default condiciones;