import React, { Component } from "react";
import JsonData from '../data/dataOthers.json';

class Otros extends Component {


    constructor(props) {
        super(props)

        this.pagina1 = "";
        this.pagina2 = "";
        this.pagina3 = "";
        this.pagina4 = "";


        this.state = {
            data: [],
            pagina1: "",
            pagina2: "",
            pagina3: "",
            pagina4:""

        }

    }

    //funcion que carga el json con las paginas a mostrar en un estado
    getlandingPageData() {
        this.setState({ data: JsonData })
    }


    //funcion que se carga al momento de cargar la pagina y llama a la funcion getlandingPageData
    componentDidMount() {
        this.getlandingPageData();

    }


    render() {


        //condicion usada para cargar los props en variables y poder ocultar las paginas que no deberian ser mostradas
        if (this.props.data != null && this.state.data != null && this.state.data.productos != null) {
            var index = this.props.data.id - 1;
            this.pagina1 = this.state.data.productos[index].paginaNo1;
            this.pagina2 = this.state.data.productos[index].paginaNo2;
            this.pagina3 = this.state.data.productos[index].paginaNo3;
            this.pagina4 = this.state.data.productos[index].paginaNo4;

        }

        //renderiza el componente otros productos en los productos de los componentes SIGEP y paginas

        return (
            <div id="Others">




                <div className="container">

                    <div className="section-title">
                        <h2>Otros productos que podrían interesarte</h2>
                    </div>
                    <div className="card-flex">
                        {this.state.data.productos && this.state.data.productos.map(t =>

                            <div >
                                {this.pagina1 == t.id || this.pagina2 == t.id || this.pagina3 == t.id || this.pagina4 == t.id ||
                                    <div className="card-img">

                                        {t.img &&
                                            <a href={t.link} className="HLink">
                                                <img className="img" src={t.img} alt="" />


                                            </a>
                                        }
                                        <a href={t.link} className="rotate"></a>

                                        {t.vid &&
                                            <a href={t.link} className="HLink">
                                                <video className="video" src={t.vid} autoPlay loop preload="auto" type="video/mp4"></video>
                                            </a>
                                        }

                                        <a href={t.link}> <h3>{t.name}</h3></a>

                                        <div className="text-box">
                                            <p >{t.text}</p>
                                        </div>


                                        <div className="button">
                                            <a className="button-box" href={t.link}>{t.button} </a>
                                        </div>


                                    </div>
                                }
                            </div>

                        )}

                    </div>
                </div>
            </div>
        )
    }
}


export default Otros;
