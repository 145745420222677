import React, { Component } from "react";

import ReCAPTCHA from "react-google-recaptcha";
//import emailjs from "emailjs-com";
export class Contact extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = { value: 'default', isGoing: false, };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
  }
  componentDidMount() {

  }
 


  handleChange(event) {

    this.setState({ value: event.target.value });
  }


  handleSubmit(event) {
    alert('Your favorite flavor is: ' + this.state.value);
    event.preventDefault();
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  sendEmail(e) {
    e.preventDefault();

  }

  onChangeRecaptcha(value) {
    console.log(value);
    var button = document.getElementById("Envio-button")
    if (value === null || value === undefined) {
      button.disabled = true;
    } else {
      button.disabled = false;
    }
  }

  componentDidMount() {
    if (this.captchaDemo) {
      console.log("started, just a second...")
      this.captchaDemo.reset();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  onErrorRecaptcha(){
    if (this.captchaDemo) {
      this.captchaDemo.forceUpdate();
      console.log("Forcing");
    }
  }


  render() {

    // componente encargado de renderizar el formulario en  la ruta "/"
    return (
      <div id="contact">
        <div id="c-Contact">
          <div className="container" id="contact2">

          


            <div className="col-md-12 contactSection2">
              <div className="row">
                <div className="section-title" id="contact-title">
                  <h2>¡Solicita una asesoría virtual gratis!</h2>
                  <p>
                    Complete el formulario a continuación para enviarnos un correo electrónico y nosotros
                    nos comunicaremos con usted lo antes posible.
                  </p>
                </div>

                <div id="loading_screen" ></div>


                <form name="sentMessage" id="contactForm" noValidate>

                  {/*
                <div className="input_container">
                    <input type="text" className="input" value />
                    <img src="https://cdn4.iconfinder.com/data/icons/36-slim-icons/87/calender.png" className="input_img" />
                </div>
                */ }

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Nombres y apellidos"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Correo electrónico"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="numberPhone"
                          className="form-control"
                          placeholder="Número de teléfono"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="companyName"
                          className="form-control"
                          placeholder="Nombre de la compañía"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="job"
                          className="form-control"
                          placeholder="Cargo"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="city"
                          className="form-control"
                          placeholder="Ciudad"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Escribe el mensaje por el cual nos contactas."
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="form-group">
                    <select className="form-control" id="consult" value={this.state.value} onChange={this.handleChange} required >
                      <option selected value="default">Selecciona un tema de consulta</option>
                      <option value="NOMINA">Producto de Nómina</option>
                      <option value="GESTION_HUMANA">Producto Gestión Humana</option>
                      <option value="CONTRATISTA">Pago Contratistas</option>

                      <option value="SEGURIDAD Y SALUD EN EL TRABAJO">Seguridad y salud en el Trabajo</option>
                      <option value="OUTSOURCING">Outsourcing</option>
                      <option value="SUGERENCIAS">Sugerencias</option>
                      <option value="SOLICITUDES">Solicitudes</option>
                      <option value="RECLANOS">Reclamos</option>
                    </select>

                    {this.state.value == "default" && <p id="combo-box" className="help-block text-danger"></p>}
                  </div>


                  <div className="button-flex">

                    <div className="form-group">

                      <input
                        name="isGoing"
                        className="contact-item"
                        type="checkbox"
                        checked={this.state.isGoing}
                        onChange={this.handleInputChange}
                        required />


                      <span className="link-contact">
                      Acepto <a href="/conditions" target="_blank" className="contact-a" > la política de protección de datos personales </a>
                        
                           
                       
                      </span>



                      <p className="help-block text-danger"></p>

                    </div>
                    <div id="success"></div>
                    <button type="submit" disabled id="Envio-button" className="btn btn-custom btn-lg" onClick={() => this.sendEmail}>
                      Enviar formulario
                    </button>
                    <div>
                      {/* You can replace captchaDemo with any ref word */}

                      <ReCAPTCHA
                        ref={(el) => { this.captchaDemo = el; }}
                        sitekey="6Lc3KHIaAAAAAGzQfQi8wQwsEwoC7W0zS3O1Nrs6"
                        onChange={this.onChangeRecaptcha}
                        render="explicit"
                      />

                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Contact;
