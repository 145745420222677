import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import Navigation from './navigation';
import Contact from '../components/pageContact';
import IconosPymes from "../data/IconosPymes.json"
import Footer from './footer';
import JsonData from '../data/data.json';
import Others from "./otrosProductos"
import Clientes from "./clientes"


export default function NominaPymes(props) {

    const [iconos, setIconos] = useState(IconosPymes);
    const [ciudades, setCiudades] = useState(JsonData);
    const [empleados, setEmpleados] = useState(0);
    const [valorSolucion, setValorSolucion] = useState(0);

    let meta = ({
        title: 'Software de nómina y gestión empresarial | HGS',
        description: 'Organiza, controla y administra el recurso humano de tu empresa a través de nuestro software de nómina y gestión empresarial. Más información aquí.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'software de gestión empresarial'
            }
        }
    });
    document.getElementsByTagName("META")[2].content = 'Organiza, controla y administra el recurso humano de tu empresa a través de nuestro software de nómina y gestión empresarial. Más información aquí.';

    let contador = 1;

    const producto = {}
    producto.id = 7;

    const handleChange = event => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        let totalEmpleados = Number(value);
        setEmpleados(totalEmpleados);
    }

    useEffect(() => {
        calcularValor();
    }, [empleados]);

    function calcularValor() {
        let valorUsuario = 0;
        let valorMes = 0;
        let total = 0;
        switch (true) {
            case (empleados < 1):
                setValorSolucion(0);
                break;
            case (empleados <= 10):
                valorMes = 690948;
                valorUsuario = 67022;
                break;
            case (empleados > 10 && empleados <= 15):
                valorUsuario = 48782;
                valorMes = 754361;
                break;
            case (empleados > 15 && empleados <= 25):
                valorUsuario = 35933;
                valorMes = 926108;
                break;
            case (empleados > 25 && empleados <= 50):
                valorUsuario = 19985;
                valorMes = 1030155;
                break;
            case (empleados >= 50 && empleados <= 80):
                valorUsuario = 16570;
                valorMes =  1366598;
                break;

            case (empleados > 80):
                valorUsuario = 14775;
                valorMes =  1523196;
                break;

            default:
                setValorSolucion(0);
                break;
        }

        console.log(valorUsuario, valorMes);
        if(valorUsuario != 0 && valorMes != 0){
            total = valorUsuario * empleados;
            total = total + valorMes;
            setValorSolucion(total);
        }

    }

    return (
        <DocumentMeta {...meta}>
            <Navigation />
            <div className="contact">
                <Contact data={"Nomina Pymes"} />
            </div>
            <div id="nominaPymes">
                <div className="container">
                    <div className="col-md-12" style={{ margin: "5em 0em 5em 0em" }}>

                        <div className="section-title" >
                            <h1>Heinsohn Nómina Pymes</h1>
                        </div>

                        <div className="text flex-column-center">
                            <p style={{ height: "auto" }}>Una <strong>solución tecnológica</strong> simple e ideal, que te brindará la mejor experiencia en procesos de liquidación de nómina.</p>
                        </div>

                        <div className="text flex-row-center" style={{ marginTop: "1em" }}>
                            <p style={{ width: "50em", height: "auto" }}><strong>Porque Heinsohn Sabe y tú eliges </strong> <br />
                                <strong><span style={{ color: "#BE0031", width: "50em", height: "auto" }}>¡Heinsohn Nómina Pymes la mejor alternativa!</span></strong> </p>
                        </div>

                        <div className="col-md-12 flex-row-center" style={{ marginBottom: "5rem", marginTop: "3em" }}>
                            {iconos.icons.map((d, i) =>
                                <div className="col-md-3 flex-column-center" style={{ width: "19em" }}>
                                    <img src={d.iconName} className="icons-image" alt="" />
                                    <p >{d.iconText}</p>
                                </div>
                            )}
                        </div>

                        <h2>Dile adiós a procesos desgastantes y errores al liquidar</h2>
                    </div>
                </div>
                <div className="nominas-pymees-White">
                    <div className="container" style={{ padding: "5em 0em 5em 0em" }}>
                        <div style={{ marginBottom: "1em" }}>
                            <h2>En <span style={{ color: "#BE0031" }}>Heinsohn</span> nos encargamos de todo</h2>
                        </div>

                        <div className="col-md-6">
                            <img src="/img/products/nomina-pymes.webp" alt="nomina pymes" style={{ width: "100%", objectFit: "cover" }} />
                        </div>
                        <div className="col-md-6">

                            <div style={{ marginTop: "2em" }}>
                                {
                                    iconos.list.map((d, i) =>
                                        <div className="parent">
                                            <div className={"div" + contador}>
                                                <i style={{ color: "#BE0031", fontSize: "2em" }} aria-hidden="true" class="fa fa-check-circle"></i>

                                            </div>
                                            <div className={"div" + contador + 1} >
                                                <ul>
                                                    <li style={{ color: "#BE0031", fontSize: "1.5em" }}><strong>{d.listTitle}</strong></li>
                                                    <li>{d.listText}</li>
                                                </ul>
                                            </div>
                                        </div>


                                    )
                                }
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="section-title" style={{ marginTop: "6em" }}>
                                <h2>Todo en una misma solución</h2>
                            </div>

                            <div className="col-md-8 parent2">

                                <div className="div1" style={{ borderBottom: "1.5px dashed #777", borderRight: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                    <h2 className="text-left">

                                        Integración y Compatibilidad
                                    </h2>
                                    <ul style={{ listStyle: "disc", paddingLeft: "1em" }}>
                                        <li>Con diferentes sistemas contables</li>
                                        <li>Bancos – Archivos planos para pago</li>
                                        <li>PILA – Operadores de seguridad social</li>
                                    </ul>
                                </div>

                                <div className="div11" style={{ borderBottom: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                    <h2 className="text-left">Seguridad</h2>
                                    <ul style={{ listStyle: "disc", paddingLeft: "1em" }}>
                                        <li>Log’s de Auditoria</li>
                                        <li>Roles y perfiles</li>
                                        <li>Creación automática de usuarios</li>
                                    </ul>
                                </div>

                                <div className="div3" style={{ borderRight: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                    <h2 className="text-left">Reportes</h2>
                                    <ul style={{ listStyle: "disc", paddingLeft: "1em" }}>
                                        <li>Listados Parametrizados</li>
                                        <li>Informes de Revisión en Excel</li>
                                        <li>Reportes de auditoria</li>
                                        <li>Tableros de control</li>
                                        <li>Informes visuales interactivos</li>
                                        <li>Información Exógena para la DIAN</li>
                                    </ul>
                                </div>

                                <div className="div4" style={{ padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                    <h2 className="text-left">
                                        Autogestión
                                    </h2>
                                    <ul style={{ listStyle: "disc", paddingLeft: "1em" }}>
                                        <li>Portal de colaboradores web</li>
                                        <li>App Móvil Gratuita</li>
                                        <li>Kiosko de Autoservicio </li>
                                        <li>Trámites y Solicitudes </li>
                                        <li>Compatible con home office</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 cellphone-pymes flex-row-center">
                                <img src="/img/pages/celular.webp" alt="celular" />
                            </div>

                        </div>

                    </div>

                </div>
                <div className="container">
                    <div className="col-md-12 flex-row-center" style={{ marginBottom: "5rem", marginTop: "6em" }}>
                        {iconos.icons2.map((d, i) =>
                            <div className="col-md-3 flex-column-center" style={{ width: "14em" }}>
                                <img src={d.iconName} className="icons-image" alt="" />
                                <p >{d.iconText}</p>
                            </div>
                        )}
                    </div>
                    <div className="col-md-12" style={{ marginBottom: "5em" }}>
                        <h2>
                            <span style={{ color: "#BE0031" }}>
                                El desafío es centralizar todas tus tareas en una misma solución
                            </span>
                            <br />
                            mejorar la imagen de tu Empresa y que uses tu tiempo en lo que tú necesites!.
                        </h2>
                    </div>
                </div>

                <div className="nominas-pymees-White">
                    <div className="container">
                        <div className="section-title" style={{ marginTop: "3em" }}>
                            <h2>Características de <span style={{ color: "#BE0031" }}>Nuestro Software</span> </h2>
                        </div>

                        <div className="col-md-12">

                            <div className="col-md-6">
                                <ul className="list-with-colors">
                                    <li className="flex-column-center" style={{ background: "#BE0031 0% 0% no-repeat padding-box", borderRadius: "20px 20px 0px 0px", opacity: "1" }}>
                                        <h2 style={{ color: "#ffff" }}>La solución más completa del Mercado</h2>
                                    </li>
                                    <li className="subtitle">
                                        <h2 style={{ textAlign: "left" }}>Hoja de vida</h2>
                                    </li>
                                    <li>
                                        <p>
                                            Gestiona tus colaboradores: aspirantes, activos, retirados y reingresos.
                                        </p>

                                    </li>
                                    <li className="text1">
                                        <p>
                                            Administra información académica, familiar y experiencia laboral
                                        </p>
                                    </li>
                                    <li>Con estructuras por centros de costo y/o proyectos</li>
                                    <li className="subtitle">
                                        <h2 style={{ textAlign: "left" }}>Administra tu nómina con herramientas que facilitan la gestión</h2>
                                    </li>
                                    <li>
                                        <p>
                                            Depuración automática de Retefuente
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Gestión de préstamos, embargos y libranzas
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Generación de Comprobantes de Pago por e-mail
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Administración de Ausentismos: vacaciones, licencias e incapacidades

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Registro de Horas Extras y Recargos
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Control de pagos y descuentos
                                        </p>
                                    </li>
                                    <li>
                                        Cargue de novedades en formato Excel
                                    </li>
                                    <li className="subtitle">
                                        <h2 style={{ textAlign: "left" }}>
                                            Tu aliado en procesos de Ley
                                        </h2>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Traslado anual de Cesantías a fondos
                                        </p>
                                    </li>
                                    <li>
                                        Liquidación de Cesantías Parciales
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Porcentaje Semestral de Retención Automático
                                        </p>
                                    </li>
                                    <li>
                                        Incrementos Masivos de Salario
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Generación de Certificados de Ingresos y Retenciones
                                        </p>
                                    </li>
                                    <li>
                                        Envóp automático de CIR’s a correo corporativo
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-with-colors">
                                    <li className="flex-column-center" style={{ background: "#BE0031 0% 0% no-repeat padding-box", borderRadius: "20px 20px 0px 0px", opacity: "1" }}>
                                        <h2 style={{ color: "#ffff" }}>APP Móvil y Portales de Autogestión</h2>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Consulta días pendientes de vacaciones
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            Generación de certificados laborales
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Registro y solicitud de incapacidades y vacaciones
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Consulta de entidades de afiliación
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Notificar cambios de cuenta bancaria
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Reportar traslados de EPS y Fondos de Pensión
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Gestión equipo de trabajo
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Organigrama de puestos de trabajo
                                        </p>
                                    </li>
                                </ul>

                                <ul className="list-with-colors" style={{ marginTop: "3em" }}>
                                    <li className="flex-column-center" style={{ background: "#BE0031 0% 0% no-repeat padding-box", borderRadius: "20px 20px 0px 0px", opacity: "1" }}>
                                        <h2 style={{ color: "#ffff" }}>APP Móvil y Portales de Autogestión</h2>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Administración de roles y perfiles
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            Creación automática de usuarios
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Plataforma Cloud Computing
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Infraestructura Cloud 100% administrada
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Nube Comunitaria (esquema compartido)
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Control de acceso con: Usuario y contraseña
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Generación Automática del archivo para PILA
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Planos estándar a Bancos
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Compatible con todos los operadores de seguridad social
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Catálogo contable estándar y NIF
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Pagos a Terceros (ACF – Voluntarios – Bancos y Otros proveedores)
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Interfaz Contable Estándar y Predefinida e integración con otros sistemas contables
                                        </p>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-md-12" style={{ marginTop: "3em", marginBottom: "10em" }}>

                            <h2>¡Dale un plus a tu empresa y al <span style={{ color: "#BE0031" }}>mejor precio</span>!</h2>

                            <h2><span style={{ color: "#BE0031" }}>¡Ahora todo el conocimiento, experiencia y respaldo de Heinsohn</span>  en un alternativa de nómina y gestión humana ideal para Pymes!.</h2>

                            <div className="section-title" style={{ marginTop: "3em" }}>
                                <h2 style={{ fontSize: "2em" }}>¿Qué Adquieres?</h2>
                            </div>

                            <div className="col-md-4">
                                <ul className="list-with-colors">
                                    <li className="flex-column-center" style={{ background: "#BE0031 0% 0% no-repeat padding-box", borderRadius: "20px 20px 0px 0px", opacity: "1" }}>
                                        <h2 style={{ color: "#ffff" }}>Nómina PYMES</h2>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Liquidación + Novedades + Gestión de colaboradores
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            Nómina electrónica
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Portal de Autogestión
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            App Móvil
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Reportes estándar y de ley para Entidades de control
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Tableros de control e informes interactivos
                                        </p>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="list-with-colors">
                                    <li className="flex-column-center" style={{ background: "#BE0031 0% 0% no-repeat padding-box", borderRadius: "20px 20px 0px 0px", opacity: "1" }}>
                                        <h2 style={{ color: "#ffff" }}>Implementación</h2>
                                        <p style={{ height: "auto", color: "#FFFFFF", fontSize: "1em", font: "italic normal 300 15px/19px Montserrat" }}>Acompañamiento con un líder de Proyecto</p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Configuración de conceptos y promedios de norma estándar de ley
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            Activación de reportes estándar
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Cargue de histórico del último año
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Modelos de certificación laboral: Hasta 3 modelos
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Ubicación del empleado por centro de costo y proyecto
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Parametrización de su informe contables
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Capacitación y acompañamiento en salida en vivo virtual
                                        </p>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="list-with-colors">
                                    <li className="flex-column-center" style={{ background: "#BE0031 0% 0% no-repeat padding-box", borderRadius: "20px 20px 0px 0px", opacity: "1" }}>
                                        <h2 style={{ color: "#ffff" }}>Soporte</h2>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Equipo de asesoría y soporte especializado
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            Formación continua para tu equipo de trabajo
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Soporte post-venta
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Capacitación online
                                        </p>
                                    </li>
                                    <li className="text1">
                                        <p>
                                            Actualizaciones y cambios de ley sin costo adicional
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Nuevas funcionalidades incluidas en el servicio
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">

                    <div className="col-md-12">
                        <div className="col-md-6 flex-row-center consultar-pymes">
                            <img src="/img/pages/Consultor-nomina.webp" alt="" style={{ objectFit: "cover", width: "100%" }} />
                        </div>
                        <div className="col-md-6 flex-column-center" style={{ marginTop: "3em", marginBottom: "3em" }} >
                            <h2>¿Cuánto vale nuestra <span style={{ color: "#AF2436" }}>solución?</span></h2>
                            <h3 style={{ marginTop: "3em", textAlign: "center" }}>¿Cuantos colaboradores tienes en tu pyme?</h3>

                            <form action="" className="flex-row-center">
                                <input min={0} max={250} value={empleados} onChange={handleChange} type="text" style={{ width: "5em", height: "3em", borderRadius: "1em", fontSize: "2em", fontWeight: "bolder", textAlign: "center", color: "#000000" }} />
                            </form>

                            <h3 style={{ textAlign: "center" }}>El valor de tu solución es:</h3>

                            <h3 style={{ color: "#BE0031", fontSize: "2em", fontWeight: "bolder" }}><span>$</span><span>{new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 3 }).format(valorSolucion)}</span></h3>
                            <span style={{ marginBottom: "1em", textAlign: "center" }}>El valor de la solución equivale a un mes de suscripción</span>

                            <button className="btn btn-custom btn-lg">
                                AMPLIAR INFORMACIÓN
                            </button>

                            <span style={{ textAlign: "center", marginTop: "2em" }}>
                                <strong>
                                    ¿Tienes requerimientos especiales?
                                </strong>
                                <br />
                                No te preocupes podemos ofrecerte una gama completa de servicios complementarios
                            </span>
                        </div>
                    </div>
                </div>

                <div className="nominas-pymees-White">
                    <div className="container" style={{ paddingTop: "5em"}}>
                        <h2><span style={{ color: "#AF2436" }}>¡Una solución práctica y accesible que puede adaptarse al crecimiento de tú Empresa!</span> <br />
                            ¡Porque Heinsohn Sabe, lo hacemos realidad! solución?</h2>


                        <div className="col-md-12 parent2">

                            <div className="div1" style={{ borderBottom: "1.5px dashed #777", borderRight: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">
                                    ¿Cómo se determina el precio a pagar?
                                </h2>
                                <p>
                                    El precio es calculado con base en la cantidad de empleados activos registrados en el sistema, cada empleado tiene una cuenta de usuario asignada. Mientras más empleados tengas, menos pagarás por cada uno.
                                </p>

                            </div>

                            <div className="div11" style={{ borderBottom: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">¿Cuál es la frecuencia del pago de la suscripción?</h2>
                                <p>
                                    El cobro es mensual, y se calcula a partir de la cantidad de empleados activos en el sistema durante el mes.
                                </p>
                            </div>

                            <div className="div3" style={{ borderBottom: "1.5px dashed #777", borderRight: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">¿Qué servicios recibo por el pago de la mensualidad?</h2>
                                <p>
                                    El precio incluye todos los módulos de nómina y talento humano, enunciados en las características de nuestro software.
                                </p>
                            </div>

                            <div className="div4" style={{ borderBottom: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">
                                    ¿Qué pasa si tengo requerimientos especiales?
                                </h2>
                                <p>
                                    Nuestra solución es completa funcionalmente. Sin embargo, no te preocupes, podemos ofrecerte una gama de servicios complementarios, para lo cual, asignaremos un líder de proyecto que se encargará de analizar el caso.
                                </p>
                            </div>

                            <div className="div5" style={{ borderBottom: "1.5px dashed #777", borderRight: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">
                                    ¿Los requerimientos especiales tienen costo?
                                </h2>
                                <p>
                                    El líder de proyecto asignado se encargará de analizar el requerimiento y si genera costo adicional te informaremos, los precios adicionales, siempre son acordados previamente con el cliente.
                                </p>

                            </div>

                            <div className="div6" style={{ borderBottom: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">
                                    ¿El precio mensual incluye la implementación?
                                </h2>
                                <p>
                                    Sí, al momento de comenzar con el servicio, se asignará un líder de proyecto que te guiará durante todo el proceso sin costo adicional, la mensualidad incluye el acompañamiento en la implementación.
                                </p>

                            </div>

                            <div className="div7" style={{ borderBottom: "1.5px dashed #777", borderRight: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">
                                    ¿La capacitación para el uso del software tiene costo adicional?
                                </h2>
                                <p>
                                    No, nuestro servicio incluye la capacitación. Durante el proceso de implementación, nos aseguramos que cada uno de nuestros usuarios conozca lo mejor posible nuestro software. Adicionalmente, mantenemos un programa completo de formación continua, no solo a nivel de software, sino en temas relevantes de nómina, seguridad social y actualidad laboral. De la mano de expertos y conocedores en aspectos legales, mantenemos actualizados a todos nuestros clientes.
                                </p>

                            </div>

                            <div className="div8" style={{ borderBottom: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word" }}>
                                <h2 className="text-left">
                                    ¿El precio incluye soporte en el uso de la herramienta?
                                </h2>
                                <p>
                                    Si, nunca estarás solo, la mensualidad incluye soporte funcional, técnico y de ley, mediante una mesa de servicio y canales de atención complementarios, que son monitoreados bajo un estándar de acuerdos de niveles de servicio conocidos por todos nuestros clientes. Nuestro modelo de asistencia cumple con los más altos estándares internacionales como ISAE3402, ISO27001, ISO9001, CMMI level 5 for Services; y la medición de satisfacción NPS (Net Promoter Score) con una de las calificaciones más altas del mercado.
                                </p>

                            </div>

                        </div>

                        <div className="col-md-12" style={{ borderBottom: "1.5px dashed #777", padding: "1em 0em 0em 1em", wordBreak: "break-word", marginTop: "2em" }}>
                            <h2 className="text-left">
                                ¿Qué pasa si requiero recurso humano para operar el software?
                            </h2>
                            <p>
                                Nuestros clientes cuentan con una amplia gama de opciones:
                            </p>
                            <ul style={{ listStyle: "disc", paddingLeft: "1em" }}>
                                <li>
                                    Acceso a personas con capacitación tanto en la herramienta, como en temas de nómina, seguridad social y actualidad laboral; formadas en nuestros programas de semilleros de capacitación.
                                </li>
                                <li>
                                    Servicios de Outsourcing continuo, asistido o por tiempo limitado, donde nuestros clientes contaran con personal calificado siguiendo las mejores prácticas del mercado en la operación de procesos de nómina y talento
                                </li>
                            </ul>
                        </div>

                        <div id="nominas" className="col-md-12" >
                            <div className="col-md-12 casos-box" style={{ paddingTop: "10em" }}>
                                <div class="videoExito" style={{ position: "relative", bottom: "3em" }}>
                                    <iframe className="videoCasos" src="https://www.youtube.com/embed/08wmh1u8V0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="clientes-nomina">
                        <Clientes data={ciudades.Clientes}></Clientes>
                    </div>
                </div>
            </div>
            <Others data={producto} />
            <Footer data={ciudades.Ciudades} />


        </DocumentMeta>
    )
}