import React, { Component } from "react";
import JsonData from '../data/data.json';
import Navigation from './navigation';
import Footer from './footer';
import Contact from '../components/pageContact';
import Others from "./otrosProductos"
import DocumentMeta from 'react-document-meta';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';






export class Pagina extends Component {

    constructor(props) {
        super(props)


        this.state = {
            landingPageData: {},
        }
        this.id = "";



    }


    //funcion que carga el json con las paginas a mostrar en un estado

    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }

    //funcion que se carga al momento de cargar la pagina y llama a la funcion getlandingPageData

    componentDidMount() {
        this.getlandingPageData();
        var url = document.referrer;
        console.log(url);
    }

    createScript() {
        const script = document.createElement("script");
        script.src = "//script.crazyegg.com/pages/scripts/0107/3672.js";
        script.async = true;
        document.body.appendChild(script);
    }

    createScript1507() {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-10943515778";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "js/script15072022.js";
        document.head.appendChild(script2);
    }

    createScriptPixel() {
        const script = document.createElement("script");
        script.src = "js/pixelScript.js"
        document.head.appendChild(script);
    }

    render() {
        //componente encargado de renderizar las mayoria de las paginas de los productos
        if (this.props.data !== undefined) {
            this.id = this.props.data.id
            if (this.id === 1) {
                this.createScript();
                this.createScriptPixel();
                this.createScript1507();
            } else if (this.id === 6) {
                this.createScriptPixel();
                this.createScript1507();
            }
            else if (this.id === 4) {
                this.createScript1507();
            }
        }


        let meta;

        if (this.props.data !== undefined) {

            meta = {

                title: this.props.data.title,

                description: this.props.data.description,

                meta: {

                    charset: 'utf-8',

                    name: {

                        keywords: this.props.data.keyword2

                    }

                }

            };

            document.getElementsByTagName("META")[2].content = this.props.data.description;

        }
        return (
            <DocumentMeta {...meta}>

                <div >
                    <Navigation />



                    <div className="contact">
                        <Contact data={this.props.data ? this.props.data.name : "Loading"} />
                    </div>




                    <div id="pagina">
                        <div className="container">


                            <div className="col-md-12">

                                <div className="section-title" >
                                    <h1>{this.props.data ? this.props.data.name : "Loading"}</h1>

                                </div>

                                <div className="text">
                                    {this.props.data ? this.props.data.subtitles && this.props.data.subtitles.map((d, i) =>
                                        <h2>{d.subtitle}</h2>
                                    ) : 'loading'}

                                </div>

                                <div className="text">
                                    {this.props.data ? this.props.data.texts && this.props.data.texts.map((d, i) =>
                                        <div>
                                            <p>{d.text}</p>
                                            <h2>{d.textBold}</h2>
                                        </div>
                                    ) : 'loading'}
                                </div>




                            </div>


                            {this.props.data ? this.props.data.img &&
                                <div className="col-md-4">

                                    {this.props.data ? this.props.data.img && this.props.data.img.map((d, i) =>
                                        <img className="img" src={d.image} alt="" />
                                    ) : 'loading'}

                                </div>
                                : "loading"}

                            {this.props.data ? this.props.data.icons &&
                                <div>
                                    <h5 className="beneficios col-md-8">Beneficios</h5>
                                </div>
                                : "loading"}

                            {this.props.data ? this.props.data.iconsBig &&
                                <div>
                                    <h5 className="beneficios col-md-12">Características</h5>
                                </div>
                                : "loading"}



                            {this.props.data ? this.props.data.iconsTop && this.props.data.iconsTop.map(i =>
                                <div className="col-md-8 icons-top">
                                    <img src={i.iconName} alt="" className="img-icon" />
                                    <h5>{i.iconText}</h5>
                                </div>
                            ) : "loading"}

                            {this.props.data ? this.props.data.icons &&

                                <div className="col-md-8 beneficios-box">
                                    {this.props.data ? this.props.data.icons && this.props.data.icons.map((d, i) =>
                                        <div className="col-md-3 img-flex">
                                            <img src={d.iconName} className="icons-image" alt="" />
                                            <p>{d.iconText}</p>
                                        </div>
                                    ) : 'loading'}
                                </div>
                                : "loading"}

                            {this.props.data ? this.props.data.iconsBig &&

                                <div className="col-md-12 beneficios-box" style={{ marginBottom: "5rem" }}>
                                    {this.props.data ? this.props.data.iconsBig && this.props.data.iconsBig.map((d, i) =>
                                        <div className="col-md-3 imgBig-flex">
                                            <img src={d.iconName} className="icons-image" alt="" />
                                            <p>{d.iconText}</p>
                                        </div>
                                    ) : 'loading'}
                                </div>
                                : "loading"}

                            {this.props.data ? this.props.data.iconsBigInline &&

                                <div className="col-md-12 beneficios-box" style={{ marginBottom: "5rem" }}>
                                    {this.props.data ? this.props.data.iconsBigInline && this.props.data.iconsBigInline.map((d, i) =>
                                        <div className="col-md-3 imgBig-flex-inline">
                                            <img src={d.iconName} className="icons-image" alt="" />
                                            <p>{d.iconText}</p>
                                        </div>
                                    ) : 'loading'}
                                </div>
                                : "loading"}

                        </div>

                    </div>
                    {this.props.data ? this.props.data.imgBanner && this.props.data.imgBanner.map((d, i) =>
                        <div id="bannerPage">
                            <div className="container">
                                <div className="col-md-12 banner">
                                    <img className="imgBanner" src={d.banner} alt="" />
                                </div>
                            </div>
                        </div>

                    ) : 'loading'}

                    {this.props.data ? this.props.data.nominas &&
                        <div id="nominas">
                            <div className="container">

                                <div className="col-md-12 Flex-beneficios">
                                    {this.props.data ? this.props.data.nominas && this.props.data.nominas.map(v =>
                                        <div className="flex-icon">
                                            <img src={v.menuIcon} alt="" className="img-icon" />
                                            <p>{v.menuTitle}</p>
                                            <h5>{v.menuText}</h5>
                                        </div>
                                    ) : "loading"}
                                </div>

                                <div className="col-md-12 img-tab-box">
                                    {this.props.data ? this.props.data.tabs && this.props.data.tabs.map(v =>
                                        <div className="col-md-6 float">
                                            <img src={v.img} alt="" className="img-tab" />
                                            <h5 className="tab-box-title">{v.title}</h5>
                                            <div className="tab-box">

                                                {v.texts.map(t =>
                                                    <p>{t.text}</p>
                                                )}
                                                <ul>
                                                    {v.lists && v.lists.map(l =>
                                                        <li>{l.list}</li>
                                                    )}
                                                </ul>

                                            </div>
                                        </div>
                                    ) : "loading"}
                                </div>




                                {this.props.data ? this.props.data.exito && this.props.data.exito.map(v =>
                                    <div className="col-md-12 casos-box">
                                        <div className="section-title">
                                            <h2 >{v.title}</h2>
                                        </div>


                                        <div class="videoExito">
                                            <iframe className="videoCasos" src="https://www.youtube.com/embed/08wmh1u8V0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>

                                        <div className="link-box">
                                            <a className="link-text" href={v.link}>{v.text}</a>
                                        </div>
                                    </div>
                                ) : "loading"}
                            </div>
                        </div> : "loading"}

                    {this.props.data ? this.props.data.nominasBig &&
                        <div id="nominasBig">
                            <div className="container">

                                <div className="col-md-12 Flex-beneficios">
                                    {this.props.data ? this.props.data.nominasBigText && this.props.data.nominasBigText.map(v =>
                                        <div className="textNominasBig">
                                            <p>{v.text}</p>
                                        </div>
                                    ) : "loading"}
                                    {this.props.data ? this.props.data.nominasBig && this.props.data.nominasBig.map(v =>
                                        <div className="flex-icon">
                                            <img src={v.menuIcon} alt="" className="img-icon" />
                                            <p>{v.menuText}</p>
                                        </div>
                                    ) : "loading"}
                                </div>
                            </div>
                        </div> : "loading"}




                    <Others data={this.props.data} />

                    <Footer data={this.state.landingPageData.Ciudades} />


                </div>
            </DocumentMeta>
        )
    }
}

export default Pagina;
