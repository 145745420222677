import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useLocation} from "react-router-dom";


export class ContactEbook extends Component {

  constructor(props) {
    super(props)

    this.e_book = "";

    this.state = {
      form: {
        name: "",
        email: "",
        phone: "",
        companyName: "",
        job: "",
        city: "",
        message: "",

      },

      isGoing: false,
      cargando: false
    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);

  }

  //funcion que recibe el formulario y evita que reacargue la pagina luego llama a la funcion send form y reinicia el formulario
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ cargando: true })
    try {
      await this.sendForm();
      this.setState({

        form: {
          name: "",
          email: "",
          phone: "",
          companyName: "",
          job: "",
          city: "",
          message: "",

        },
        isGoing: false,
      });

      // document.getElementsByName("contactForm").reset();
    } catch (e) {
      alert(`Registration failed! ${e.message}`);
    }
  }


  //funcion encargada de enviar el formulario y dependiendo de la respuesta valida si debe descargar el e-book o no
  sendForm = async () => {


    var utm_source = new URLSearchParams(window.location.search).get('utm_source');

    var utm_medium = new URLSearchParams(window.location.search).get('utm_medium');

    var utm_campaign = new URLSearchParams(window.location.search).get('utm_campaign');

    var template = `<body><h4>La siguiente informacion ha sido enviada por el remitente</h4> 
    <p><strong>Nombre y apellido:</strong> ${this.state.form.name} </p>
    <p><strong>Email:</strong> ${this.state.form.email} </p>
    <p><strong>Teléfono:</strong> ${this.state.form.phone} </p>
    <p><strong>Empresa:</strong> ${this.state.form.companyName} </p>
    <p><strong>Cargo:</strong> ${this.state.form.job} </p>
    <p><strong>Ubicacion:</strong> ${this.state.form.city} </p>
    <p><strong>Area de consulta:</strong> ${this.e_book} </p>
    <p><strong>Mensaje:</strong> ${this.state.form.message}</p>
    <p>Acepto términos: Consented: Autorizo el envío de información sobre Heinsohn a mi teléfono o correo electrónico</p></body> `

    if (utm_campaign !== null && utm_campaign !== undefined && utm_campaign !== "") {
      var template = `<body><h4>La siguiente informacion ha sido enviada por el remitente</h4> 
      <p>Campaign name: ${utm_campaign}</p>
      <p>Campaign source: ${utm_source}</p>
      <p>Campaign medium: ${utm_medium}</p>
      <p><strong>Nombre y apellido:</strong> ${this.state.form.name} </p>
      <p><strong>Email:</strong> ${this.state.form.email} </p>
      <p><strong>Teléfono:</strong> ${this.state.form.phone} </p>
      <p><strong>Empresa:</strong> ${this.state.form.companyName} </p>
      <p><strong>Cargo:</strong> ${this.state.form.job} </p>
      <p><strong>Ubicacion:</strong> ${this.state.form.city} </p>
      <p><strong>Area de consulta:</strong> ${this.e_book} </p>
      <p><strong>Mensaje:</strong> ${this.state.form.message}</p>
      <p>Acepto términos: Consented: Autorizo el envío de información sobre Heinsohn a mi teléfono o correo electrónico</p></body> `
    } 
    const URLAPI = "https://moviles.heinsohn.com.co/api/parameter/sendEmail"
    const response = await fetch(URLAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      cache: 'no-cache',
      body: JSON.stringify({

        "subject": this.e_book,
        'name': this.state.form.name,
        'email': this.state.form.email,
        'message': this.state.form.message,
        'phone': this.state.form.phone,
        'companyName': this.state.form.companyName,
        'job': this.state.form.job,
        'city': this.state.form.city,
        'consult': this.e_book,
        'template': template,
      })
    })
    if (response.status === 200) {
      document.getElementById('descargar').click();
      this.setState({ cargando: false })
    }
    if (response.status !== 200) {
      this.setState({ cargando: false })
      throw new Error(`Request failed: ${response.status}`);

    }
  }

  //funcion encargada de validar el cambio del checkbox

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    if (this.captchaDemo) {
      console.log("started, just a second...")
      this.captchaDemo.reset();
    }

  }

  //funcion encargada de realizar los cambios en los estados del formulario
  handleChnage = async e => {

    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });

  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }



  onChangeRecaptcha(value) {
    var button = document.getElementById("Envio-button")
    if (value === null || value === undefined) {
      button.disabled = true;
    } else {
      button.disabled = false;
    }
  }

  onErrorRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.forceUpdate();
      console.log("Forcing");
    }
  }


  render() {
    if (this.props.data.E_book !== null) {
      this.e_book = "Descarga e-book " + this.props.data.E_book
    }

    //se renderiza el formulario dentro de la ruta "/nomina-electronica"
    return (
      <div>
        <div id="contactebook">
          <div className="container" id="contact3">

            <div className="col-md-6">
              <div className="row">
                <div className="section-title" id="contact-title">
                  <h2>Descargar el e-book</h2>

                </div>

                <div id="loading-form" style={{ visibility: this.state.cargando ? "visible" : "hidden" }}>

                  <div >
                    <strong>Loading...</strong>
                  </div>
                  <div className="circle">

                  </div>


                </div>


                <form id="contactForm" onSubmit={this.onSubmit} style={{ visibility: this.state.cargando ? "hidden" : "visible" }} >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="name"
                          value={this.state.form.name}
                          onChange={this.handleChnage}
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Nombres y apellidos"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="email"
                          value={this.state.form.email}
                          onChange={this.handleChnage}
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Correo electrónico"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="phone"
                          value={this.state.form.phone}
                          onChange={this.handleChnage}
                          type="number"
                          id="numberPhone"
                          className="form-control"
                          placeholder="Número de teléfono"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="companyName"
                          value={this.state.form.companyName}
                          onChange={this.handleChnage}
                          type="text"
                          id="companyName"
                          className="form-control"
                          placeholder="Nombre de la compañía"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="job"
                          value={this.state.form.job}
                          onChange={this.handleChnage}
                          type="text"
                          id="job"
                          className="form-control"
                          placeholder="Cargo"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="city"
                          value={this.state.form.city}
                          onChange={this.handleChnage}
                          type="text"
                          id="city"
                          className="form-control"
                          placeholder="Ciudad"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      value={this.state.form.message}
                      onChange={this.handleChnage}
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Escribe el mensaje por el cual nos contactas."
                      required
                    ></textarea>
                  </div>
                  <div className="button-flex">

                    <div className="form-group">
                      <span className="link-contact">
                        <a href="/conditions" target="_blank" className="contact-a" >
                          Acepto la política de protección de datos personales
                        </a>

                      </span>

                      <input
                        name="isGoing"
                        className="contact-item"
                        type="checkbox"
                        checked={this.state.isGoing}
                        onChange={this.handleInputChange}
                        required />


                    </div>

                    <button type="submit" disabled id="Envio-button" className="btn btn-custom btn-lg">
                      ¡Descarga el e-book!
                    </button>
                    <div className="recaptcha">
                      {/* You can replace captchaDemo with any ref word */}
                      <ReCAPTCHA
                        ref={(el) => { this.captchaDemo = el; }}
                        sitekey="6Lc3KHIaAAAAAGzQfQi8wQwsEwoC7W0zS3O1Nrs6"
                        onChange={this.onChangeRecaptcha}
                        render="explicit"
                      />

                    </div>
                  </div>

                </form>


                <a target="_blank" href={this.props.data ? this.props.data.link : "loading"} id="descargar"
                  download="Nomina.pdf"
                >descargar
                </a>
              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default ContactEbook;
