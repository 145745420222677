import React, { Component } from "react";
import Navigation from './navigation';
import JsonData from '../data/data.json';

import Footer from './footer';




export class Content extends Component {

    constructor(props) {
        super(props)


        this.state = {
            landingPageData: {},
   
        }

    }


    // funcion que carga los datos del json en un estado
    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }

    // funcion encargada de llamar a la funcion getlandingPageData al momento de que la pagina esta renderizada
    componentDidMount() {
        this.getlandingPageData();
    }

    render() {

        //componente que renderiza el contenido de los e-book en la ruta "/contenido/e-books"
        return (
            <div id="content-c">
                <Navigation />
                <div className="container">

                    <div className="col-md-12 section-title">
                        <h1>{this.props.data ? this.props.data.title : "loading"}</h1>
                    </div>
                    <div className="box">

                        {this.props.data ? this.props.data.ebooks && this.props.data.ebooks.map((d, i) =>
                            <div className="col-md-6">
                                <div className="image-box">
                                    <img className="img" src={d.img} alt="" />
                                </div>

                                <div className="ebook-box">

                                    <div >
                                        <a href={d.link} className="link"><h4>{d.title}</h4></a>
                                    </div>

                                    <div className="texts-box">
                                        {d.texts.map(t =>
                                            <p className="ebook-text">{t.text}</p>
                                        )}
                                    </div>

                                    <div className="link-box">
                                        <a href={d.link} className="link">{d.textLink}</a><i className="icon-arrow-58 icon-ebooks"></i>
                                    </div>
                                </div>

                            </div>
                        ) : 'loading'}
                    </div>

                </div>
                <Footer data={this.state.landingPageData.Ciudades} />


            </div>
        )
    }

}

export default Content;