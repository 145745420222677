import React, { Component } from "react";
import Navigation from './navigation';
import JsonData from '../data/data.json';
import Footer from './footer';



export class NotFound extends Component {


    constructor(props) {
        super(props)


        this.state = {
            landingPageData: {},

        }

    }

    //funcion que carga el json en un estado
    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }


    //funcion que se carga al momento de cargar la ruta "/message" y llama a la funcion getlandingPageData
    componentDidMount() {
        this.getlandingPageData();
    }

    render() {

        //se renderiza si aparece un 404
        return (
            <div id="NotFound">

                <Navigation />
                <div className="container">

                    <div className="title">
                        <h1>404 Not found</h1>
                        <p>Contenido no encontrado, si escribió la url manualmente por favor revise</p>
                    </div>
                </div>
                <Footer data={this.state.landingPageData.Ciudades} />

            </div>
        )
    }
}

export default NotFound;