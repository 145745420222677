import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Navigation extends Component {
  render() {

    //componente que renderiza el menu de navegacion en todas las paginas

    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="/#header">
              <img src="/img/hgs-logo.svg" className="logo-img" alt="HGS - Human Global Solutions" />
            </a>{" "}
          </div>

          <div
            style={{ overflow: "hidden" }}
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/#servicios" className="page-scroll"  >
                  Servicios
                </a>
              </li>
              <li>
                <a href="/#productos" className="page-scroll" >
                  Productos
                </a>
              </li>
              <li>
                <a href="/#aliados" className="page-scroll" >
                  Aliados
                </a>
              </li>
              <li>
                <a href="/#nosotros" className="page-scroll" >
                  Nosotros
                </a>
              </li>
              <li>
                <a href="/#clientes" className="page-scroll" >
                  Clientes
                </a>
              </li>
              {/* <li>
                <a href="#team" className="page-scroll">
                  Team
                </a>
              </li> */}


              <li class="dropdown">
                <button class="dropbtn">Contenido</button>
                <div class="dropdown-content">
                  <a href="/e-books">E-books</a>
                  <a href="/blogs">Blogs</a>
                </div>
              </li>



              <li>
                <a href="/#contact" className="page-scroll" >
                  Contacto
                </a>
              </li>



            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;

