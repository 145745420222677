import React, { Component } from 'react'
import { Router } from 'react-router-dom';
import history from './services/history'
import Routes from './services/Routers'


export class App extends Component {
  render() {
    // componente que renderiza dependiendo de la ruta
    
    
    //Comentar para emitir mensajes en la consola
    //console.warn = () => {};
    //console.error = () => {};

    return (

      <Router history={history}>
        <Routes />
        <a
          href="https://wa.me/573158408133"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
          <div className="whatsapp-box">
          <p>¿Como podemos ayudarte?</p>
        </div>
        
        </a>
        
      </Router>
    )
  }
}

export default App;
