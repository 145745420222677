import React, { Component } from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
import Aliados from './components/aliados';
import Productos from './components/productos';
import Servicios from './components/servicios';
import Nosotros from './components/nosotros';
import Gallery from './components/gallery';
import Clientes from './components/clientes';
import Team from './components/Team';
import Contact from './components/contact';
import JsonData from './data/data.json';
import Footer from './components/footer';
import Empresa from "./components/empresa"
import DocumentMeta from 'react-document-meta';

export class Client extends Component {
  state = {
    landingPageData: {},
  }

  //funcion que carga el json con la informcion de las paginas a mostrar en un estado

  getlandingPageData() {
    this.setState({ landingPageData: JsonData })

  }

  //funcion que se carga al momento de cargar la pagina y llama a la funcion getlandingPageData

  componentDidMount() {

    debugger;
    let url = window.location.href;

    let type = url.split("#");

    if(type.length > 0){
      switch(type[1]){
        case "contact":
          window.location.href = "https://heinsohn.co/heinsohn-nomina-y-gestion-humana/";
          break;
  
        case "clientes":
          window.location.href = "https://heinsohn.co/heinsohn-nomina-y-gestion-humana/";
          break;

        case "nosotros":
          window.location.href = "https://heinsohn.co/nosotros/";
          break;

        case "aliados":
          window.location.href = "https://heinsohn.co/clientes-y-partners/";
          break;

        default:
          window.location.href = "https://heinsohn.co/heinsohn-nomina-y-gestion-humana/";
          break;
      }
    }else{
      window.location.href = "https://heinsohn.co/clientes-y-partners/";
    }


    this.getlandingPageData();
  }

  render() {
    let meta;
    meta = {
        title: 'Software de nómina y gestión empresarial | HGS',
        description: 'Organiza, controla y administra el recurso humano de tu empresa a través de nuestro software de nómina y gestión empresarial. Más información aquí.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'software de gestión empresarial'
            }
        }
    };
    document.getElementsByTagName("META")[2].content = 'Organiza, controla y administra el recurso humano de tu empresa a través de nuestro software de nómina y gestión empresarial. Más información aquí.';
    //componente que renderiza en la pagina principal, ademas de que es el encargado de enviar la informacion en la ruta "/"
    return (
      <div>
        <DocumentMeta {...meta}>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <Empresa data={this.state.landingPageData.Empresas} />
        <Servicios data={this.state.landingPageData.Servicios} />
        <Productos data={this.state.landingPageData.ProductosV2} />
        <Aliados data={this.state.landingPageData.Aliados} />
        <Nosotros data={this.state.landingPageData.Nosotros} />
        {/* <Gallery /> */}
        <Clientes data={this.state.landingPageData.Clientes} />
        {/* <Team data={this.state.landingPageData.Team} /> */}
        <Contact data={this.state.landingPageData.Ciudades} />
        

        <Footer data={this.state.landingPageData.Ciudades} />
        </DocumentMeta>

      </div>
    )
  }
}

export default Client;