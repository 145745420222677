import React, { Component } from "react";

export class Services extends Component {
  render() {

    //componente encargadod de renderizar los servicios en la ruta "/"
    return (
      <div id="servicios" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Servicios</h2>
            <p>
              Procesos formales de operación y personal capacitado para apoyar tus métodos de atención a colaboradores, liquidación de pagos y la entrega confiable de información a terceros y tu organización.
            </p>
          </div>
          <div className="d-flex flex-wrap">
            {this.props.data
              ? this.props.data.map((d, i) => (
                <a href={d.urlBton} target="_blank" className="col-xs-12 col-md-4 box-services">
                  <div key={'${d.name}-${i}'} >
                    {" "}
                    <img src={d.icon} alt="" />
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                     
                    </div>
                    <div className="buton">
                    <a  href={d.urlBton}>{d.nameBton}</a>
                    </div>
                    
                  </div>
                </a>
              ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
