import React, { Component } from "react";

export class clientes extends Component {


  stars(){
    let star=[];
    
    for (let index = 0; index <= 4; index++) {
      
     star.push(<span className="fa fa-star"></span>)
    }
    
    return(
      <div className="testimonial-star">
        {star}
      </div>
    )
    
      
    
  }
  //renderiza la seccion clientes en la ruta "/"
  render() {
    return (
      <div id="clientes">
        <div className="container">
          <div className="section-title text-center">
            <h2>Lo que dicen nuestros clientes</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-3">
                    <div className="testimonial">
                      <div className="testimonial-image">
                        {" "}
                        <img src={d.img} alt="" />{" "}
                      </div>
                      <div className="testimonial-meta"> {d.name} </div>
                      <div className="testimonial-company">{d.company}</div>
                      <hr className="testimonial-separator"/>
                      <div className="testimonial-content">
                        <p>"{d.text}"</p>
                       
                       {this.stars()}
                        
                      
                      </div>
                      
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default clientes;
