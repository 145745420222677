import React, { Component } from "react";
import Navigation from './navigation';
import JsonData from '../data/data.json';
import DocumentMeta from 'react-document-meta';


import Footer from './footer';

export default class SG_SSTEbook extends Component {

    constructor(props) {
        super(props)

        this.state = {
            landingPageData: {},

        }

    }

    //funcion que carga el json en un estado

    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }
    //funcion que se carga al momento de cargar la ruta "/nomina-electronica" y llama a la funcion getlandingPageData
    componentDidMount() {
        this.getlandingPageData();
    }

    render() {
        let meta;
        if (this.props.data !== undefined) {
            meta = {
                title: this.props.data.title,
                description: this.props.data.description,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: this.props.data.keyword2
                    }
                }
            };
            document.getElementsByTagName("META")[2].content=this.props.data.description;
        }

        return (
            <DocumentMeta {...meta}>
                <div id="blogs">
                    <Navigation />
                    <div className="container underlineSec">

                        <div class="col-md-12 img-box">
                            <img className="img" src={this.props.data ? this.props.data.banner : "loading"} alt={this.props.data ? this.props.data.alt :"loading"} />
                        </div>

                        <div className="col-md-12 content">
                            <h1>{this.props.data ? this.props.data.seccion1Title : "Loading"} <a href={this.props.data ? this.props.data.seccion1Link : "Loading"}>{this.props.data ? this.props.data.seccion1linkText : "Loading"}</a> {this.props.data ? this.props.data.seccion1Text2 : "Loading"} </h1>
                            <div>
                                {this.props.data ? this.props.data.seccion1.map(t =>
                                    <div>
                                        {t.text && <p>{t.text} <a href={t.link}>{t.linkText}</a> <a href={t.link2}>{t.linkText2}</a> <strong>{t.textBold}</strong> {t.text2} <a href={t.link3}>{t.linkText3}</a> <strong>{t.textBold2}</strong> {t.text3}   </p>}
                                        {t.subtitleh2 && <h2>{t.subtitleh2}</h2>}
                                        {t.list &&
                                            <ul>
                                                <li>{t.listTitle && <h3>{t.listTitle}</h3>} {t.listC && <p>{t.listC}</p>}{t.list} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.list2}{t.listS} <strong>{t.textBoldS}</strong> {t.listSS}{t.list3 && <p><br/>{t.list3}</p>} {t.list4 && <p>{t.list4} <strong>{t.textBold2}</strong> {t.list5} <strong>{t.textBold3}</strong>{t.list6}</p> } </li>
                                            </ul>
                                        }
                                        {t.list2 &&
                                            <ul className="seccion4-list2">
                                                <li>{t.list2} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.text2}</li>
                                            </ul>
                                        }
                                        {t.subtitle && <h3>{t.subtitle}</h3>}
                                    </div>
                                ) : "loading"}
                            </div>
                        </div>

                        <div className="col-md-12">
                            <h2>{this.props.data ? this.props.data.seccion2Title : "Loading"}<a href={this.props.data ? this.props.data.seccion2Link : "Loading"}> {this.props.data ? this.props.data.seccion2linkText : "Loading"}</a> </h2>
                            <div>
                                {this.props.data ? this.props.data.seccion2.map(t =>
                                    <div>
                                        {t.text && <p>{t.text} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.text2} <a href={t.link2} target="_blank">{t.linkText2}</a> <strong>{t.textBold2}</strong> {t.text3}</p>}
                                        {t.list &&
                                            
                                            <ul>
                                                
                                                <li>{t.listTitle && <h3>{t.listTitle}</h3>}  {t.list} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.list2}</li>
                                            </ul>
                                        }
                                        {t.subtitle && <h3>{t.subtitle}</h3>}
                                    </div>
                                ) : "loading"}
                            </div>
                        </div>

                        <div className="col-md-12">
                            {this.props.data ? this.props.data.seccion3.map(t =>
                                <div>
                                    {t.title && <h2>{t.title} <a href={t.link}>{t.linkText}</a> </h2>}
                                    {t.text && <p>{t.text} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.text2}  <a href={t.link2}>{t.linkText2}</a> <strong>{t.textBold2}</strong> {t.text3} <a href={t.link3}>{t.linkText3}</a> <strong>{t.textBold3}</strong> {t.text4}</p>}
                                    {t.list &&
                                        <ul>
                                            <li>{t.listTitle && <p><strong>{t.listTitleStrong}</strong>{t.listTitle}</p>} {t.listTitleBold && <strong>{t.listTitleBold}</strong>} {t.list} <strong>{t.listBold}</strong> <a href={t.listLink}>{t.listLinkText}</a> {t.list2}</li>
                                        </ul>
                                    }
                                    {t.subtitle && <h3>{t.subtitle}</h3>}
                                </div>
                            ) : "loading"}

                        </div>
                        {this.props.data && this.props.data.seccion4 &&
                            <div className="col-md-12 seccion4">
                                <h2>{this.props.data ? this.props.data.seccion4Title : "Loading"} <a href={this.props.data ? this.props.data.seccion4Link : "Loading"}> {this.props.data ? this.props.data.seccion4linkText : "Loading"}</a> </h2>
                                {this.props.data ? this.props.data.seccion4.map(t =>
                                    <div>
                                        {t.text && <p>{t.text} <a href={t.linkNomia}>{t.linktextNomina}</a> <strong>{t.textBold}</strong> {t.text2} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold2}</strong> {t.text3} <a href={t.link2}>{t.linkText2}</a> <strong>{t.textBold3}</strong> {t.text4} <a href={t.link3}>{t.linkText3}</a> {t.text5}</p>}
                                        {t.list &&
                                            <ul>
                                                <li>{t.list} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.text2}</li>
                                            </ul>
                                        }
                                        {t.list2 &&
                                            <ul className="seccion4-list2">
                                                <li>{t.list2} <a href={t.link}>{t.linkText}</a> <strong>{t.textBold}</strong> {t.text2}</li>
                                            </ul>
                                        }
                                        {t.subtitle && <h3>{t.subtitle}</h3>}
                                        {t.subtitleh2 && <h2>{t.subtitleh2}</h2>}
                                    </div>
                                ) : "loading"}
                            </div>
                        }

                        {this.props.data && this.props.data.referencias &&

                            <div className="col-md-12 referencias">
                                {this.props.data ? this.props.data.referencias.map(t =>
                                    <div>
                                        <p>{t.text} <a href={t.link} target="_blank">{t.link}</a> </p>
                                    </div>
                                ) : "loading"}
                            </div>
                        }



                    </div>
                    <Footer data={this.state.landingPageData.Ciudades} />

                </div>
            </DocumentMeta>
        )
    }
}

