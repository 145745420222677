import React, { Component } from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import condiciones from '../components/condiciones';
import NominaPymes from "../components/NominaPymes"
import politicasHGS from '../components/PoliticasHeinsohnHGS'
import mensajeEnviado from '../components/mensajeEnviado';
import client from '../client';
import Pagina from '../components/Pagina';
import SIGEP from "../components/SIGEP";
import NotFoundPage from "../components/NotFoundPage";
import ContentEbooks from "../components/contentEbooks";
import Ebooks from "../components/Ebooks";
import Blogs from "../components/Blogs";
import ContentBlogs from "../components/contentBlogs";


import Politicas from "../components/PoliticasGenerales";

import ContentData from "../data/contentData.json";

import JsonData from '../data/dataPages.json';

import politicasData from "../data/politicasSeguridadInformacion.json";


import contentPageBlogs from "../data/blogs_data.json";

import BlogBeneficiosSigep from "../data/blogs/BeneficiosSigep.json";
import BlogDefinicionSistemasSeguridad from "../data/blogs/DefinicionSistemaSeguridadsalud.json";
import BlogGestionTalento from "../data/blogs/GestionTalento.json";
import BlogLiquidacion from "../data/blogs/LiquidacionNomina.json";
import BlogNominaElectronica from "../data/blogs/NominaElectronicaColombia.json";
import BlogSGSST from "../data/blogs/SGSST.json";
import BlogTecnicasSeleccion from "../data/blogs/TecnicasDeSeleccionPersonal.json";
import BlogVentajasSoftwareNomina from "../data/blogs/VentajasDesventajasSoftwareNomina.json";
import BlogOutsourcing from "../data/blogs/OutsourcingNomina.json";
import BlogEvitarRiesgos from "../data/blogs/evitarRiesgos.json";
import BlogSaludEmpleados from "../data/blogs/saludDeLosEmpleados.json";
import BlogOptimizacionPersonal from "../data/blogs/OptimizacionPersonalSoftware.json";
import BlogTransformacionDigital from "../data/blogs/TransformacionDigital.json";
import BlogAutomatizacionOutsourcing from "../data/blogs/AutomatizacionOutsourcing.json";
import BlogImportanciaSyS from "../data/blogs/ImportanciaSYS.json";
import BlogEmpresaAtractiva from "../data/blogs/EmpresaMasAtractiva.json";
import BlogDiferenciasNominas from "../data/blogs/DiferenciasNominaPrivadaYGobierno.json";
import BlogTransformacionDigitalEnEmpresas from "../data/blogs/TransformacionDigitalEnEmpresas.json";
import BlogDesarrolloTalentoHumano from "../data/blogs/DesarrolloDelTalentoHumano.json";
import BlogFuncionesGestionHumana from "../data/blogs/FuncionesGestionHumana.json";
import BlogHabilidadesYCompetencias from "../data/blogs/HabilidadesYCompetencias.json";
import BlogSeguimientoDePersonal from "../data/blogs/SeguimientoDePersonal.json";
import BlogTiposDeReclutamiento from "../data/blogs/TiposDeReclutamiento.json";
import BlogRetosNominaElectronica from "../data/blogs/RetosNominaElectronica.json";
import BlogTendenciasBienestarLaboral2022 from "../data/blogs/TendenciasBienestarLaboral2022.json";
import BlogPlanDeCarreraEmpresarial from "../data/blogs/PlanDeCarreraEmpresarial.json";
import BlogBeneficiosEvaluacionDesempeno from "../data/blogs/BeneficiosEvaluacionDesempeno.json";
import BlogGetionYOptimizacionSSG from "../data/blogs/GetionYOptimizacionSSG.json";
import BlogLiquidacionIncapacidades from "../data/blogs/LiquidacionIncapacidades.json";
import BlogGestionTalentoHumano from "../data/blogs/GestionTalentoHumano.json";
import BlogDigitalizaTD from "../data/blogs/DigitalizaTransformacionDigital.json";
import BlogGestionYAdministracionSST from '../data//blogs/GestionaYAdministraLosProcesosPreventivos.json';
import BlogPagoIncapacidadesLaborales from '../data/blogs/SomosTuMejorAliadoPagoIncapacidadesLaborales.json';
import BlogGestionSaludyTrabajo from '../data/blogs/GestionSaludyTrabajo.json';
import GestionIncapacidadesSoftware from '../data/blogs/GestionIncapacidadesSoftware.json';
import SoftwareTalentoHumano from '../data/blogs/SoftwareTalentoHumano.json';
import SoftwareSectorFinanciero from '../data/blogs/ImplementacionSoftwareSectorFinanciero.json';
import VentajasNominaSectorFinanciero from '../data/blogs/VentajasNominaSectorFinanciero.json';
import TercerizacionDeNomina from "../data/blogs/TercerizacionDeNomina.json";
import VentajasUsoDeSoftwareNomina from "../data/blogs/VentajasDeUsoDeSoftwareNomina.json";
import ImplementacionNominaElectronica from "../data/blogs/ImplementacionNominaElectronica.json";
import CambiosSignificativosSGSSTCovid19 from "../data/blogs/CambiosSignificativosCovid19.json";
import MejoraProcesoDeSeleccionConSoftware from "../data/blogs/MejoraProcesoDeSeleccionConUnSoftware.json";
import VentajasProgramasParaNomina from "../data/blogs/VentajasProgramasParaNomina.json";
import ClavesSGSSTSectorSalud from "../data/blogs/ClavesSGSSTSectorSalud.json";
import DesafiosSoftwareRecursosHumanos from "../data/blogs/DesafiosSoftwareRecursosHumanos.json";
import GestionDeIncapacidades from "../data/blogs/GestionDeIncapacidades.json";
import UsoDeSoftwareParaTalentoHumano from "../data/blogs/UsoDeSoftwareParaTalentoHumano.json";
import BeneficiosSeguridadySaludTrabajo from "../data/blogs/BeneficiosSeguridadySaludTrabajo.json";
import LiquidacionNominaEmpresasComerciales from "../data/blogs/LiquidacionNominaEmpresasComerciales.json";
import IncapacidadesSectorManufacturero from "../data/blogs/IncapacidadesSectorManufacturero.json";
import SoftwareGestionRRHH from "../data/blogs/SoftwareGestionRRHH.json";
import TendenciasSeguridadSaludTrabajoColombia from "../data/blogs/TendenciasSeguridadSaludTrabajoColombia.json";
import CaracteristicasSoftwareNomina from "../data/blogs/CaracteristicasSoftwareNomina.json";
import DebesSabrSoftwareRH from "../data/blogs/DebesSabrSoftwareRH.json";
import DesarrolloTalentoSoftware from "../data/blogs/DesarrolloTalentoSoftware.json";
import BeneficiosSolucionesNubeSoftware from "../data/blogs/BeneficiosSolucionesNubeSoftware.json";



export default class Routes extends Component {

  state = {
    landingPageData: {},
    contentPage: {},
    politicasData: {},
    blog: {},
    contentPageBlogs: {}
  }
  //funcion que carga el json con la informcion de las paginas a mostrar en un estado

  getlandingPageData() {
    const Blog = {
      SG_SST: BlogSGSST,
      LiquidacionNomina: BlogLiquidacion,
      GestionTalento: BlogGestionTalento,
      DefinicionSistemaSeguridadsalud: BlogDefinicionSistemasSeguridad,
      VentajasDesventajasSoftwareNomina: BlogVentajasSoftwareNomina,
      NominaElectronicaColombia: BlogNominaElectronica,
      TecnicasDeSeleccionPersonal: BlogTecnicasSeleccion,
      BeneficiosSigep: BlogBeneficiosSigep,
      BlogOutsourcing: BlogOutsourcing,
      BlogEvitarRiesgos: BlogEvitarRiesgos,
      BlogSaludEmpleados: BlogSaludEmpleados,
      BlogOptimizacionPersonal: BlogOptimizacionPersonal,
      BlogTransformacionDigital: BlogTransformacionDigital,
      BlogAutomatizacionOutsourcing: BlogAutomatizacionOutsourcing,
      BlogImportanciaSyS: BlogImportanciaSyS,
      BlogEmpresaAtractiva: BlogEmpresaAtractiva,
      BlogDiferenciasNominas: BlogDiferenciasNominas,
      BlogTransformacionDigitalEnEmpresas: BlogTransformacionDigitalEnEmpresas,
      BlogDesarrolloTalentoHumano: BlogDesarrolloTalentoHumano,
      BlogFuncionesGestionHumana: BlogFuncionesGestionHumana,
      BlogHabilidadesYCompetencias: BlogHabilidadesYCompetencias,
      BlogSeguimientoDePersonal: BlogSeguimientoDePersonal,
      BlogTiposDeReclutamiento: BlogTiposDeReclutamiento,
      BlogRetosNominaElectronica: BlogRetosNominaElectronica,
      BlogTendenciasBienestarLaboral2022: BlogTendenciasBienestarLaboral2022,
      BlogPlanDeCarreraEmpresarial: BlogPlanDeCarreraEmpresarial,
      BlogBeneficiosEvaluacionDesempeno: BlogBeneficiosEvaluacionDesempeno,
      BlogGetionYOptimizacionSSG: BlogGetionYOptimizacionSSG,
      BlogLiquidacionIncapacidades: BlogLiquidacionIncapacidades,
      BlogGestionTalentoHumano: BlogGestionTalentoHumano,
      BlogDigitalizaTD: BlogDigitalizaTD,
      BlogGestionYAdministracionSST: BlogGestionYAdministracionSST,
      BlogPagoIncapacidadesLaborales: BlogPagoIncapacidadesLaborales,
      BlogGestionSaludyTrabajo: BlogGestionSaludyTrabajo,
      GestionIncapacidadesSoftware: GestionIncapacidadesSoftware,
      SoftwareTalentoHumano: SoftwareTalentoHumano,
      TercerizacionDeNomina: TercerizacionDeNomina,
      SoftwareSectorFinanciero: SoftwareSectorFinanciero,
      VentajasNominaSectorFinanciero: VentajasNominaSectorFinanciero,
      VentajasUsoDeSoftwareNomina: VentajasUsoDeSoftwareNomina,
      ImplementacionNominaElectronica: ImplementacionNominaElectronica,
      CambiosSignificativosSGSSTCovid19: CambiosSignificativosSGSSTCovid19,
      MejoraProcesoDeSeleccionConSoftware: MejoraProcesoDeSeleccionConSoftware,
      VentajasProgramasParaNomina: VentajasProgramasParaNomina,
      ClavesSGSSTSectorSalud: ClavesSGSSTSectorSalud,
      DesafiosSoftwareRecursosHumanos: DesafiosSoftwareRecursosHumanos,
      GestionDeIncapacidades: GestionDeIncapacidades,
      UsoDeSoftwareParaTalentoHumano: UsoDeSoftwareParaTalentoHumano,
      BeneficiosSeguridadySaludTrabajo: BeneficiosSeguridadySaludTrabajo,
      LiquidacionNominaEmpresasComerciales: LiquidacionNominaEmpresasComerciales,
      IncapacidadesSectorManufacturero: IncapacidadesSectorManufacturero,
      SoftwareGestionRRHH: SoftwareGestionRRHH,
      TendenciasSeguridadSaludTrabajoColombia: TendenciasSeguridadSaludTrabajoColombia,
      CaracteristicasSoftwareNomina: CaracteristicasSoftwareNomina,
      DebesSabrSoftwareRH: DebesSabrSoftwareRH,
      DesarrolloTalentoSoftware: DesarrolloTalentoSoftware,
      BeneficiosSolucionesNubeSoftware: BeneficiosSolucionesNubeSoftware
    }
    this.setState({ landingPageData: JsonData, contentPage: ContentData, politicasData: politicasData, blog: Blog, contentPageBlogs: contentPageBlogs })

  }

  //funcion que se carga al momento de cargar la pagina y llama a la funcion getlandingPageData

  componentDidMount() {
    this.getlandingPageData();
  }





  render() {
    //componente encargado del manejo de las rutas en la app
    return (
      <BrowserRouter >
        <Switch>

          <Route exact path="/" component={client} />

          {/*
         <Route exact path="/" component={() => {
            window.location.href = 'https://heinsohn.co/heinsohn-nomina-y-gestion-humana';
            return null;
          }} />
         */}

          <Route exact path='/conditions' component={condiciones} />
          <Route exact path='/message' component={mensajeEnviado} />
          <Route exact path='/politicasHGS' component={politicasHGS} />

          <Route exact path="/software-de-nomina" component={() => {
            window.location.href = 'https://heinsohn.co/heinsohn-nomina-y-gestion-humana/software-de-nomina';
            return null;
          }} /> {/*<Pagina data={this.state.landingPageData.softwareNomina} /> */}

          <Route exact path="/gestion-de-reconocimiento-economicos" component={() => {
            window.location.href = 'https://heinsohn.co/heinsohn-nomina-y-gestion-humana/gestion-de-reconocimiento-economicos';
            return null;
          }} />
          {/* <Pagina data={this.state.landingPageData.reconocimientosEconomicos} />*/}

          <Route exact path="/seguridad-y-salud-en-el-trabajo" component={() => {
            window.location.href = 'https://heinsohn.co/heinsohn-nomina-y-gestion-humana/seguridad-y-salud-en-el-trabajo/';
            return null;
          }} />
          {/*<Pagina data={this.state.landingPageData.seguridadySalud} /> */}

          <Route exact path="/software-de-gestion-del-talento-humano" component={() => {
            window.location.href = "https://www.heinsohn.co/heinsohn-nomina-y-gestion-humana/software-de-gestion-del-talento-humano/";
            return null;
          }} />
          {/*<Pagina data={this.state.landingPageData.gestionTalento} />*/}

          <Route exact path="/nomina-pymes" component={NominaPymes} />

          <Route exact path="/implementacion-y-consultoria-sigep" component={() => {
            window.location.href = 'https://heinsohn.co/heinsohn-nomina-y-gestion-humana/soluciones-de-nomina-y-gestion-humana-para-gobierno'
            return null;
          }} />
          {/*<Pagina data={this.state.landingPageData.SIGEP} />*/}

          <Route exact path="/outsourcing-de-nomina" component={() => {
            window.location.href = 'https://heinsohn.co/heinsohn-nomina-y-gestion-humana/outsourcing-de-nomina-y-administracion-del-personal';
            return null;
          }} />
          {/*<Pagina data={this.state.landingPageData.OutsourcingNomina} />*/}

          <Route exact path="/e-books/nomina-electronica"><Ebooks data={this.state.contentPage.nominaElectronica} /></Route>

          <Route exact path="/e-books" component={() => {
            window.location.href = 'https://heinsohn.co/contenido/ebook';
            return null;
          }} />
          {/*<ContentEbooks data={this.state.contentPage.content} /> */}

          <Route exact path="/politica-seguridad-informacion"><Politicas data={this.state.politicasData} /></Route>

          <Route exact path="/e-books/SG-SST"><Ebooks data={this.state.contentPage.SG_SST} /></Route>

          <Route exact path="/blogs/blogSG-SST"><Blogs data={this.state.blog.SG_SST} /></Route>

          <Route exact path="/blogs" component={() => {
            window.location.href = "https://heinsohn.co/contenido/blog";
            return null;
          }} />
          {/*<ContentBlogs data={this.state.contentPageBlogs.content} />*/}


          <Route exact path="/blogs/liquidacion-nomina"><Blogs data={this.state.blog.LiquidacionNomina} /></Route>

          <Route exact path="/blogs/gestión-del-talento-humano"><Blogs data={this.state.blog.GestionTalento} /></Route>

          <Route exact path="/blogs/definicion-sistema-de-seguridad-y-salud-en-el-trabajo" component={() => {
            window.location.href = "https://heinsohn.co/empresas-en-crecimiento-definicion-de-sistema-de-gestion-de-seguridad-y-salud-en-el-trabajo/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.DefinicionSistemaSeguridadsalud} /></Route>*/}


          <Route exact path="/blogs/ventajas-y-desventajas-software-de-nomina" component={() => {
            window.location.href = "https://heinsohn.co/ventajas-y-desventajas-de-software-de-nomina-en-empresas/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.VentajasDesventajasSoftwareNomina} /></Route>*/}

          <Route exact path="/blogs/nomina-electronica-colombia-nueva-norma" component={() => {
            window.location.href = "https://heinsohn.co/nomina-electronica-colombia-para-empresas/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.NominaElectronicaColombia} /></Route> */}

          <Route exact path="/blogs/tecnicas-de-seleccion-de-personal" component={() => {
            window.location.href = "https://heinsohn.co/tecnicas-de-seleccion-de-personal/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.TecnicasDeSeleccionPersonal} /></Route>*/}


          <Route exact path="/blogs/beneficios-de-sigep" component={() => {
            window.location.href = "https://heinsohn.co/sigep-beneficios-servidores-publicos/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BeneficiosSigep} /></Route>*/}

          <Route exact path="/blogs/outsourcing-de-nomina-y-atencion-a-los-colaboradores" component={() => {
            window.location.href = "https://heinsohn.co/outsourcing-de-nomina-para-colaboradores/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogOutsourcing} /></Route>*/}

          <Route exact path="/blogs/evitar-riesgos-a-traves-de-la-gestion-de-seguridad-y-salud-en-el-trabajo" component={() => {
            window.location.href = "https://heinsohn.co/gestion-de-seguridad-y-salud-en-el-trabajo/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogEvitarRiesgos} /></Route>*/}

          <Route exact path="/blogs/incapacidades-laborales-la-forma-de-administrar-y-conocer-sobre-la-salud-de-sus-empleados" component={() => {
            window.location.href = "https://heinsohn.co/administrar-incapacidades-laborales/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogSaludEmpleados} /></Route>*/}

          <Route exact path="/blogs/optimiza-la-seleccion-de-tu-personal-con-un-software-de-recursos-humanos" component={() => {
            window.location.href = "https://heinsohn.co/optimiza-seleccion-personal-software-de-recursos-humanos/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogOptimizacionPersonal} /></Route>*/}

          <Route exact path="/blogs/transformacion-digital-en-las-empresas" component={() => {
            window.location.href = "https://heinsohn.co/transformacion-digital-empresarial/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogTransformacionDigital} /></Route>*/}

          <Route exact path="/blogs/automatizacion-con-outsourcing-de-nomina" component={() => {
            window.location.href = "https://heinsohn.co/outsourcing-de-nomina-empresarial/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogAutomatizacionOutsourcing} /></Route>*/}

          <Route exact path="/blogs/importancia-de-la-seguridad-y-salud-en-el-trabajo" component={() => {
            window.location.href = "https://heinsohn.co/seguridad-y-salud-en-el-trabajo-importancia/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogImportanciaSyS} /></Route>*/}

          <Route exact path="/blogs/haz-tu-empresa-mas-atractiva-para-los-aspirantes-con-un-software-de-talento-humano"><Blogs data={this.state.blog.BlogEmpresaAtractiva} /></Route>

          <Route exact path="/blogs/5-diferencias-de-la-nomina-privada-y-nomina-de-gobierno" component={() => {
            window.location.href = "https://heinsohn.co/nomina-de-gobierno-vs-nomina-privada/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogDiferenciasNominas} /></Route>*/}

          <Route exact path="/blogs/como-lograr-la-transformacion-digital-en-las-empresas"><Blogs data={this.state.blog.BlogTransformacionDigitalEnEmpresas} /></Route>
          <Route exact path="/blogs/desarrollo-del-talento-humano-a-traves-de-un-software-de-gestion-humana"><Blogs data={this.state.blog.BlogDesarrolloTalentoHumano} /></Route>

          <Route exact path="/blogs/funciones-de-gestion-humana" component={() => {
            window.location.href = "https://heinsohn.co/procesos-de-gestion-humana/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogFuncionesGestionHumana} /></Route>*/}

          <Route exact path="/blogs/habilidades-y-competencias-laborales"><Blogs data={this.state.blog.BlogHabilidadesYCompetencias} /></Route>

          <Route exact path="/blogs/seguimiento-de-personal" component={() => {
            window.location.href = "https://heinsohn.co/seguimiento-de-personal-en-una-empresa/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogSeguimientoDePersonal} /></Route>*/}

          <Route exact path="/blogs/tipos-de-reclutamiento" component={() => {
            window.location.href = "https://heinsohn.co/tipos-de-reclutamiento-de-personal/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogTiposDeReclutamiento} /></Route>*/}

          <Route exact path="/blogs/retos-nomina-electronica-en-colombia-2022" component={() => {
            window.location.href = "https://heinsohn.co/importancia-nomina-electronica/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogRetosNominaElectronica}></Blogs></Route>*/}

          <Route exact path="/blogs/tendencias-bienestar-laboral-2022" component={() => {
            window.location.href = "https://heinsohn.co/bienestar-laboral-ventajas-beneficios-retos/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogTendenciasBienestarLaboral2022}></Blogs></Route>*/}

          <Route exact path="/blogs/plan-de-carrera-empresarial" component={() => {
            window.location.href = "https://heinsohn.co/software-de-recursos-humanos-plan-carrera-empresarial/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogPlanDeCarreraEmpresarial}></Blogs></Route>*/}

          <Route exact path="/blogs/5-beneficios-de-la-evalucaion-de-desempeño" component={() => {
            window.location.href = "https://heinsohn.co/evaluacion-de-desempeno-empresarial/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogBeneficiosEvaluacionDesempeno}></Blogs></Route>*/}

          <Route exact path="/blogs/ImplementacionSoftwareSectorFinanciero"><Blogs data={this.state.blog.SoftwareSectorFinanciero}></Blogs></Route>
          <Route exact path="/blogs/VentajasNominaSectorFinanciero"><Blogs data={this.state.blog.VentajasNominaSectorFinanciero}></Blogs></Route>
          <Route exact path="/blogs/Gestion-y-optimizacion-seguridad-y-salud-en-el-trabajo"><Blogs data={this.state.blog.BlogGetionYOptimizacionSSG}></Blogs></Route>
          <Route exact path="/blogs/beneficios-administrar-la-liquidacion-de-incapacidades"><Blogs data={this.state.blog.BlogLiquidacionIncapacidades}></Blogs></Route>

          <Route exact path="/blogs/gestion-del-talento-humano" component={() => {
            window.location.href = "https://heinsohn.co/que-es-gestion-del-talento-humano/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogGestionTalentoHumano}></Blogs></Route>*/}

          <Route exact path="/blogs/digitaliza-los-procesos-a-traves-de-un-software"><Blogs data={this.state.blog.BlogDigitalizaTD}></Blogs></Route>

          <Route exact path="/blogs/gestiona-y-administra-los-procesos-preventivos-de-seguridad-y-salud-en-el-trabajo" component={() => {
            window.location.href = "https://heinsohn.co/modelos-prevencion-seguridad-y-salud-en-el-trabajo/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogGestionYAdministracionSST}></Blogs></Route>*/}

          <Route exact path="/blogs/somos-tu-mejor-aliado-para-la-gestion-del-pago-de-incapacidades-laborales" component={() => {
            window.location.href = "https://heinsohn.co/pago-de-incapacidades-laborales/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BlogPagoIncapacidadesLaborales}></Blogs></Route>*/}

          <Route exact path="/blogs/GestionSaludyTrabajo"><Blogs data={this.state.blog.BlogGestionSaludyTrabajo}></Blogs></Route>
          <Route exact path="/blogs/GestionIncapacidadesSoftware"><Blogs data={this.state.blog.GestionIncapacidadesSoftware}></Blogs></Route>
          <Route exact path="/blogs/SoftwareTalentoHumano"><Blogs data={this.state.blog.SoftwareTalentoHumano}></Blogs></Route>
          <Route exact path="/blogs/tercerizacion-de-nomina"><Blogs data={this.state.blog.TercerizacionDeNomina} /></Route>

          <Route exact path="/blogs/ventajas-de-uso-de-software-para-pago-de-nomina" component={() => {
            window.location.href = "https://heinsohn.co/ventajas-de-software-para-pago-de-nomina/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.VentajasUsoDeSoftwareNomina} /></Route>*/}

          <Route exact path="/blogs/implementacion-nomina-electronica" component={() => {
            window.location.href = "https://heinsohn.co/aliado-en-implementacion-de-nomina-electronica/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.ImplementacionNominaElectronica} /></Route>*/}

          <Route exact path="/blogs/cambios-significativos-SGSST-debido-al-covid19" component={() => {
            window.location.href = "https://heinsohn.co/cambios-gestion-en-seguridad-y-salud-en-el-trabajo/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.CambiosSignificativosSGSSTCovid19} /></Route>*/}

          <Route exact path="/blogs/mejora-tu-proceso-de-seleccion-con-software" component={() => {
            window.location.href = "https://heinsohn.co/seleccion-de-personal-software-para-recursos-humanos/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.MejoraProcesoDeSeleccionConSoftware} /></Route>*/}

          <Route exact path="/blogs/beneficios-seguridad-trabajo" component={() => {
            window.location.href = "https://heinsohn.co/tecnologia-en-seguridad-y-salud-en-el-trabajo/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BeneficiosSeguridadySaludTrabajo} /></Route>*/}

          <Route exact path="/blogs/claves-liquidacion-empresas" component={() => {
            window.location.href = "https://heinsohn.co/claves-liquidacion-de-nomina/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.LiquidacionNominaEmpresasComerciales} /></Route>*/}

          <Route exact path="/blogs/cobro-incapacidades-manufacturero" component={() => {
            window.location.href = "https://heinsohn.co/datos-cobro-de-incapacidades/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.IncapacidadesSectorManufacturero} /></Route>*/}

          <Route exact path="/blogs/caracteristicas-softwarerrhh" component={() => {
            window.location.href = "https://heinsohn.co/caracteristicas-software-de-gestion-de-rrhh/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.SoftwareGestionRRHH} /></Route>*/}

          <Route exact path="/blogs/saber-software-rh" component={() => {
            window.location.href = "https://heinsohn.co/saber-sobre-software-para-recursos-humanos/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.DebesSabrSoftwareRH} /></Route>*/}

          <Route exact path="/blogs/tendencias-seguridadysalud-trabajo" component={() => {
            window.location.href = "https://heinsohn.co/tendencias-seguridad-y-salud-en-el-trabajo-colombia/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.TendenciasSeguridadSaludTrabajoColombia} /></Route>*/}

          <Route exact path="/blogs/caracteristicas-software-nomina" component={() => {
            window.location.href = "https://heinsohn.co/caracteristicas-software-para-manejo-de-nomina/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.CaracteristicasSoftwareNomina} /></Route>*/}

          <Route exact path="/blogs/desarrollo-talento-software"><Blogs data={this.state.blog.DesarrolloTalentoSoftware} /></Route>

          <Route exact path="/blogs/beneficio-software-nube" component={() => {
            window.location.href = "https://heinsohn.co/beneficios-del-software-de-nomina-y-rrhh-en-la-nube/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.BeneficiosSolucionesNubeSoftware} /></Route>*/}

          <Route exact path={"/blogs/ventajas-nomina-sector"} component={() => {
            window.location.href = "https://heinsohn.co/programas-para-nominas-electronicas-sector-financiero/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.VentajasProgramasParaNomina} /></Route>*/}

          <Route exact path={"/blogs/claves-para-una-optima-gestios-de-SGSST-en-el-sector-salud"} component={() => {
            window.location.href = "https://heinsohn.co/claves-de-gestion-de-seguridad-y-salud-en-el-trabajo/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.ClavesSGSSTSectorSalud} /></Route>*/}

          <Route exact path={"/blogs/implementacion-software-financiero"}><Blogs data={this.state.blog.DesafiosSoftwareRecursosHumanos} /></Route>

          <Route exact path={"/blogs/gestion-de-incapacidades"} component={() => {
            window.location.href = "https://heinsohn.co/mejorar-gestion-de-incapacidades/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.GestionDeIncapacidades} /></Route>*/}

          <Route exact path={"/blogs/uso-de-software-para-reclutamiento-de-talento-humano"} component={() => {
            window.location.href = "https://heinsohn.co/recluta-persona-software-para-talento-humano/";
            return null;
          }} />
          {/*<Blogs data={this.state.blog.UsoDeSoftwareParaTalentoHumano} /></Route>*/}

          <Route component={NotFoundPage} />

        </Switch>
      </BrowserRouter>
    );
  }
}

