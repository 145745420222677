import React, { Component } from "react";
import Navigation from './navigation';
import JsonData from '../data/data.json';
import Contact from '../components/ContactEbook';


import Footer from './footer';


export class Ebooks extends Component {

    constructor(props) {
        super(props)

        this.state = {
            landingPageData: {},

        }

    }

    //funcion que carga el json en un estado

    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }
    //funcion que se carga al momento de cargar la ruta "/nomina-electronica" y llama a la funcion getlandingPageData
    componentDidMount() {
        this.getlandingPageData();
    }

    render() {
        return (
            <div>
                <Navigation />

                <div id="e_books">
                    <div className="container">

                        <div class="col-md-12 img-box">
                            <img className="img" src={this.props.data ? this.props.data.banner : "loading"} alt="" />
                        </div>

                        <div className="col-md-12 content">



                            <div className="col-md-5">
                                <h1>{this.props.data ? this.props.data.seccion1Title : "loading"}</h1>
                                <div>
                                    {this.props.data ? this.props.data.seccion1.map(t =>
                                        <p>{t.text}</p>
                                    ) : "loading"}
                                </div>
                            </div>

                            <div className="col-md-6 contact">

                                <Contact data={this.props.data ? this.props.data.linkEbook : "Loading"} />
                            </div>

                        </div>

                        <div className="col-md-12 seccion2">

                            <div className="col-md-5">
                                <img className="image" src={this.props.data ? this.props.data.seccion2Img : "loading"} alt="" />
                            </div>

                            <div className="col-md-6 cont">
                                <h2>{this.props.data ? this.props.data.seccion2Title : "Loading"}</h2>

                                <div className="body-text">
                                    {this.props.data ? this.props.data.seccion2.map(t =>
                                        <div>
                                            {t.text && <p>{t.text}</p>}
                                            {t.lists && <ul>
                                                {t.lists.map(list =>
                                                    <li>{list.list}</li>)}
                                            </ul>}
                                        </div>
                                    ) : "loading"}
                                </div>

                                    <a className="link" href={this.props.data ? this.props.data.link : "/"}>{this.props.data ? this.props.data.linkTitle : "Loading"}</a>
                            </div>

                        </div>

                    </div>
                    <Footer data={this.state.landingPageData.Ciudades} />

                </div>
            </div>
        )
    }
}

export default Ebooks;