import React, { Component } from 'react'

export class Footer extends Component {

    constructor(props, context) {
        super(props, context);


    }


    render() {

        //componente encargado de renderizar el footer en todas las paginas
        return (


            <div id="footer" className="col-md-12">



                <div className="col-xs-12 col-md-3 text-center  box">
                    <div>
                        <img src="/img/Logo_HGS.svg" alt="HGS - Human Global Solutions" />
                    </div>
                </div>


                <div className="col-xs-12 col-md-3 politica-box">
                    <ul>
                        <li><a href="/politicasHGS">Políticas Heinsohn HGS</a></li>
                        <li><a href="https://www.heinsohn.com.co/" rel="nofollow" target="_blank" >HGS y Mihhgs es propiedad de Heinsohn Human Global Solutions SAS</a></li>
                        <li><a href="/conditions">Términos y condiciones</a></li>
                    </ul>
                </div>



                <div >
                    {this.props.data
                        ? this.props.data.map((d, i) => (
                            <div key={'${d.name}-${i}'} className="col-xs-12 col-md-3">

                                <div className="contact-desc">
                                    <h3><i className="fa fa-map-marker"></i> {d.cityName}</h3>
                                    <p>{d.cityDir}</p>
                                    <p>Tel: {d.cityTel} Móvil: <a href={"tel:+57" + d.cityPhone}>{d.cityPhone}</a></p>
                                    <p><a href={"mailto:" + d.cityText}>{d.cityText}</a></p>

                                </div>

                            </div>
                        ))
                        : "loading"}
                </div>


                <div className="col-xs-12 col-md-3 social">
                    <ul >
                        <li>
                            <a
                                href="https://www.facebook.com/Heinsohn-HGS-106756165130791" target="_blank"
                            >
                                <i className="fa fa-facebook"></i>Facebook
                            </a>
                        </li>
                        <li>
                            <a href="https://co.linkedin.com/showcase/heinsohnhgs?trk=affiliated-pages" target="_blank">
                                <i className="fa fa-linkedin"></i>LinKedin
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer