import React, { Component } from "react";
import JsonData from '../data/data.json';
import Navigation from './navigation';
import Footer from './footer';
import Contact from '../components/pageContact';
import Others from "./otrosProductos"







export class SIGEP extends Component {

    constructor(props) {
        super(props)

        this.state = {
            landingPageData: {},
        }



    }

    //funcion que carga el json con las paginas a mostrar en un estado

    getlandingPageData() {
        this.setState({ landingPageData: JsonData })
    }

    //funcion que se carga al momento de cargar la pagina y llama a la funcion getlandingPageData

    componentDidMount() {
        this.getlandingPageData();
    }


    render() {
        //componente encargado de renderizar la pagina SIGEP
        return (

            <div >
                <Navigation />


                <div id="pagina">
                    <div className="contact">
                        <Contact data={this.props.data ? this.props.data.name : "Loading"} />
                    </div>



                    <div className="container">


                        <div className="content">

                            <div className="col-md-6">


                                {this.props.data ? this.props.data.img && this.props.data.img.map((d, i) =>
                                    <div class="p-2 bd-highlight">
                                        <img className="img" src={d.image} alt="" />
                                    </div>
                                ) : 'loading'}


                                {this.props.data ? this.props.data.vid && this.props.data.vid.map((d, i) =>
                                    <div>
                                        <video className="video" src={d.video} autoPlay loop preload="auto" type="video/mp4"></video>
                                    </div>
                                ) : 'loading'}


                            </div>

                            <div className="col-md-6">

                                <div className="section-title" id="contact-title">
                                    <h1>{this.props.data ? this.props.data.name : "Loading"}</h1>

                                </div>

                                <div>

                                    {this.props.data ? this.props.data.subtitles && this.props.data.subtitles.map((d, i) =>
                                        <h2>{d.subtitle}</h2>
                                    ) : 'loading'}

                                </div>


                                <div>
                                    {this.props.data ? this.props.data.texts && this.props.data.texts.map((d, i) =>
                                        <p>{d.text}</p>
                                    ) : 'loading'}
                                </div>

                                <div>
                                    {this.props.data ? this.props.data.lists && this.props.data.lists.map((d, i) =>
                                        <ul>
                                            <li>
                                                {d.list}
                                            </li>
                                        </ul>
                                    ) : 'loading'}

                                </div>

                                <div>
                                    {this.props.data ? this.props.data.seccion2 && this.props.data.seccion2.map((d, i) =>
                                        <p>{d.text}</p>
                                    ) : 'loading'}
                                </div>

                                <div>
                                    {this.props.data ? this.props.data.lists && this.props.data.lists.map((d, i) =>
                                        <p>{d.text}</p>
                                    ) : 'loading'}
                                </div>

                                <div>
                                    {this.props.data ? this.props.data.icons && this.props.data.icons.map((d, i) =>
                                        <div className="col-md-6 icons">
                                            <i className={d.iconName + " icon"}></i>
                                            <p>{d.iconText}</p>
                                        </div>
                                    ) : 'loading'}
                                </div>
                            </div>


                            {this.props.data ? this.props.data.seccion3 && this.props.data.seccion3.map((d, i) =>
                                <div className="col-md-10 seccion3">
                                    <p>{d.text}</p>
                                </div>
                            ) : 'loading'}


                            {this.props.data ? this.props.data.seccion2list && this.props.data.seccion2list.map((d, i) =>
                                <div className="col-md-6 ">
                                    <ul>
                                        <li>
                                            {d.list}
                                        </li>
                                    </ul>
                                </div>
                            ) : 'loading'}


                        </div>

                    </div>
                </div>
                <Others data={this.props.data} />


                <Footer data={this.state.landingPageData.Ciudades} />


            </div>

        )
    }
}

export default SIGEP;
